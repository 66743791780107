import { ShoppingCart } from "lucide-react";
import { useState } from "react";
import RegistrationContext from "../../contexts/RegistrationContext";
import { useContext } from "react";

function AddToCart({ onClickHanlder, disabled = false, animation }) {
  const { cartAdded, setCartAdded } = useContext(RegistrationContext);

  const [click, setClick] = useState(false);
  const onClickAddToCart = (e) => {
    e.preventDefault();
    onClickHanlder(e);
    if (animation == true) {
      setClick(true);
      setCartAdded(true);
      setInterval(() => {
        setClick(false);
      }, 300);
      setTimeout(() => {
        setCartAdded(false);
      }, 500);
    }
  };
  return (
    <div
      className={`addcart flex gap-2 ${!disabled && "cursor-pointer"} ${
        click && "bg-black text-white"
      }`}
      onClick={disabled ? () => {} : (e) => onClickAddToCart(e)}
      style={{ opacity: disabled ? "0.5" : "1" }}
    >
      +<ShoppingCart size={16} />
    </div>
  );
}

export default AddToCart;
