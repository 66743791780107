import {
  ChefHat,
  Contact,
  Gift,
  HandCoins,
  Mails,
  Printer,
  Ticket,
  Wine,
} from "lucide-react";
import { BarChart, PieChart } from "@mui/x-charts";
import { useEffect, useState } from "react";
import axios from "axios";
import { base_url } from "../../config";
import workshops_json from "../../util/workshops.json";
import { RiFileExcel2Line } from "@remixicon/react";

const NumberBox = ({ Icon = null, bigletters, title }) => {
  return (
    <div className="w-1/4">
      <div className="flex flex-row  shadow-lg p-6 rounded-lg w-full bg-slate-100">
        <div className="w-20  text-blueAdmin">{Icon && <Icon size={48} />}</div>
        <div className="flex flex-col gap-4 w-full">
          <div
            className="text-slate-900 text-right"
            style={{ fontSize: "3rem", lineHeight: "0.7" }}
          >
            {bigletters}
          </div>
          <div className="text-slate-700 font-thin text-right">{title}</div>
        </div>
      </div>
    </div>
  );
};

const ChartBox = ({ data, title }) => {
  if (!data) return null;
  return (
    <div className="w-1/2">
      <div className="flex flex-col items-center justify-center bg-slate-100 shadow-lg p-6 rounded-lg w-full">
        <div className="text-xl mb-4">{title}</div>
        <div className="flex w-full justify-center">
          <PieChart
            series={[
              {
                data,
              },
            ]}
            height={250}
          />
        </div>
      </div>
    </div>
  );
};


const BarChartBox = ({ title, data, item_label }) => {

  return (
    <div className="w-full">
      <div className="flex flex-col items-center justify-center bg-slate-100 shadow-lg p-6 rounded-lg w-full">
        <div className="text-xl mb-4">{title}</div>
        <div className="flex w-full justify-center">
          <BarChart
            height={250}
            series={[
              {
                data: data.map((d) => (d.value)),
                label: item_label,
              },
            ]}
            xAxis={[{ data: data.map((d) => (d.key)), scaleType: "band" }]}
          />
        </div>
      </div>
    </div>
  );
}

const downLoadExcelWorkshops = async () => {
  try {
    const response = await axios.get(`${base_url}/api/admin/export_excel_workshops`, {
      responseType: "blob",
    });
    const pdfBlob = new Blob([response.data], { type: "application/xlsx" });

    const url = window.URL.createObjectURL(pdfBlob);
    const tempLink = document.createElement("a");
    tempLink.href = url;
    tempLink.setAttribute("download", `ECMLPKDD-Workshops.xlsx`); // Set the desired filename for the downloaded file

    // Append the <a> element to the body and click it to trigger the download
    document.body.appendChild(tempLink);
    tempLink.click();

    // Clean up the temporary elements and URL
    document.body.removeChild(tempLink);
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error("Error fetching data: ", error);
  }
};

const Dashboard = () => {
  const [data, setData] = useState([]);
  const [reload, setReload] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {


    async function loadData() {
      setLoading(true);
      try {
        const comm = await axios.get(`${base_url}/api/admin/stats`);
        setData(comm.data);
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    };


    loadData();

  }, []);

  if (loading) return <div>Loading...</div>;

  /*
    This array of objects will come from the backend within data.registrations, for example.
    The backend will provide me with an array of objects where the date is not repeated. Each object 
    will have the total number of registrations on a given day.
  */


  const workshops = Object.entries(data.workshops).map(([key, value]) => ({
    key: workshops_json.filter(i => (i.id == parseInt(key)))[0].name, // Convert key back to integer if necessary
    value
  }));


  return (
    <div
      className="flex flex-col gap-4 bg-white"
      style={{ minWidth: "1280px", maxWidth: "1600px" }}
    >

      <div className="flex gap-4">
        <NumberBox
          Icon={Ticket}
          bigletters={data.ticket}
          title="Registrations"
        />
        <NumberBox
          Icon={Mails}
          bigletters={data.visa}
          title="Visa letters requested"
        />
        <NumberBox
          Icon={ChefHat}
          bigletters={data.gala_dinner}
          title="Gala dinner interest"
        />
        <NumberBox
          Icon={Contact}
          bigletters={data.women_only}
          title="Women-only event"
        />
      </div>
      <div className="flex gap-4">
        <NumberBox
          Icon={Wine}
          bigletters={data.farewell}
          title="Farewell event"
        />
        <NumberBox Icon={Printer} bigletters={data.poster} title="Poster " />
        <NumberBox
          Icon={Gift}
          bigletters={data.free}
          title="Free registrations"
        />
        <NumberBox
          Icon={HandCoins}
          bigletters={data.income + " €"}
          title="Income"
        />
      </div>
      <div className="flex gap-4">
        <ChartBox title="Registration Types" data={data.types} />
        <ChartBox title="Registration Progress" data={data.progress} />
      </div>

      <div className="flex gap-4">
        <BarChartBox
          title="Registrations per day"
          data={data.dataRegistrations.sort((a, b) => (a.date > b.date ? 1 : -1)).
            map(i => ({ key: i.date, value: i.value }))}
          item_label="number of registrations"
        />
      </div>
      <div className="flex gap-4 flex-col w-full">
        
        <div
          className=" flex justify-end cursor-pointer "
          onClick={() => downLoadExcelWorkshops()}
        >
          <div className="flex">

            <RiFileExcel2Line className="text-green-800" /> Export Workshops Interest
          </div>
        </div>
        <div className="flex">
          <BarChartBox
            title="Workhops Interest"
            data={workshops}
            item_label="number of delegates"
          />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
