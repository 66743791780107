import { useContext } from "react";
import AuthStatus from "../AuthStatus/AuthStatus";
import "./style.css";
import RegistrationContext from "../../contexts/RegistrationContext";

const NavBar = ({ boxed }) => {
  const { loading } = useContext(RegistrationContext);

  return (
    <div className={`navbar ${boxed && "boxed"}`}>
      <img src="../../../../images/logo.svg" width="1019" height="612" alt="ECML-LOGO" id="ecmlLogo" />
      {!loading && <AuthStatus />}

    </div>
  );
}

export default NavBar;
