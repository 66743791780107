import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { useContext } from "react";
import RegistrationContext from "../../contexts/RegistrationContext";
import { base_url } from "../../config";
import axios from "axios";

export default function FreePayment() {

  const { setError, reload, setReload } =
    useContext(RegistrationContext);




  const handleSubmit = async (event) => {
    // Reset login error state
    event.preventDefault();
    setError("");


    try {
      // Make a POST request to your authentication endpoint
      const response = await axios.post(`${base_url}/api/progress`, {

      });
      setReload(reload + 1);
    } catch (error) {
      setError("Unexpected error. " + error.response.data.message);
    }
  };


  return (
    <div className="flex flex-col h-full">
      <div
        style={{ maxWidth: "1152px", width: "100%" }}
        className="flex justify-center flex-col h-full items-center mx-auto lg:py-0 text-left"
      >
        <div
          style={{ maxHeight: "100%", maxWidth: "1152px", width: "100%" }}
          className="bg-white rounded-lg shadow  md:mt-0 sm:max-w-md xl:p-0  "
        >
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1>Proceed With Your Free Registration</h1>
            <p>Your registartion is Free. Please click below to finalize your registration</p>
            <div className="w-full flex justify-center">
              <button
                className="button"
                type="submit"
                onClick={(e) => handleSubmit(e)}
              >
                Finalize
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
