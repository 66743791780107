import { ShoppingCart } from "lucide-react";
import { useContext } from "react";
import RegistrationContext from "../../contexts/RegistrationContext";
import axios from "axios";
import { base_url } from "../../config";




function CartIcon({dropdownRef2}) {
  const { screen, reload, setReload, setError, setCartOpen, cartAdded } = useContext(RegistrationContext);

  const handler = async (step) => {
    setError('');
    try {
      await axios.post(`${base_url}/api/jump`, {
        step: "Cart",
      });
      setReload(reload + 1);
    } catch (error) {
      setError('Not accessible step');
    }
  }

  return (
    <div className="cart-icon cursor-pointer">
      <ShoppingCart ref={dropdownRef2} onClick={() => { setCartOpen(true)}} size={24} className={`${cartAdded && "clicked"}`}/>
    </div>
  );
}

export default CartIcon;