import React, { useContext } from 'react';
import axios from 'axios';
import RegistrationContext from '../../contexts/RegistrationContext';
import { base_url, steps } from '../../config';
import getStepValue from '../../util/getStepValue';
import './style.css';

function CheckStep() {
  return (<svg
    className="w-3.5 h-3.5 sm:w-4 sm:h-4 me-2.5"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 20 20"
  >
    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
  </svg>)
}

function Stepper() {
  const { setReload, reload, setError, screen, maxStep, type } = useContext(RegistrationContext);
  const handler = async (step) => {
    setError('');
    try {
      await axios.post(`${base_url}/api/jump`, {
        step: step,
      });
      setReload(reload + 1);
    } catch (error) {
      setError('Not accessible step');
    }
  }

  return (
    <div className='stepper_container'>
      <div>
      </div>
      <div className='stepper_box'>
        <div className='flex justify-between w-full gap-4 text-sm md:text-base'>
          {steps.map((step, index) => {
            let isOrange = false;
            let isGray = false;
            let isHidden = false;
            if (screen === 'Confirmation') {
              isOrange = (step === 'Confirmation')
              isGray = (step !== 'Confirmation');
            } else {
              isOrange = (getStepValue(step) < getStepValue(maxStep));
              isGray = (getStepValue(step) > getStepValue(maxStep));
            }
            const isWhite = (getStepValue(step) === (getStepValue(maxStep) + 1));
            const isBold = step === screen;

            if (type === 'single' && step==="Workshop Selection") {
              isHidden = true;
            }
            if (isHidden) return null;
            return <div key={index} className={"flex items-center " + (isBold ? "font-bold  " : "") + (isWhite ? "font-white " : "") + (isOrange ? "text-green-400 " : "") + (isGray ? "text-stone-400 " : "cursor-pointer")}
              onClick={(() => isGray ? null : handler(step))}>
              <div className={"hidden md:block"} style={{ width: "25px" }}>{isOrange ? (<CheckStep />) : " "} </div> <div>{step}</div>
            </div>
          })}
        </div>
      </div>
    </div>
  );
}

export default Stepper;
