import React from "react";
import './Loading.css'; // Import the CSS file

const Loading = () => {
    return (
        <div className="flex flex-col items-center justify-center min-h-screen" >
            <img className="fadeIn" src="../../../../images/logo.svg" width="1019" height="612" alt="ECML-LOGO" id="ecmlLogo" />
            <div className="fadeIn">REGISTRATION</div>
        </div >
    )
}

export default Loading;