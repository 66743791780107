import React, { useContext, useEffect, useState } from "react";
import workshops from "../../util/workshops.json";
import RegistrationContext from "../../contexts/RegistrationContext";
import axios from "axios";
import { base_url } from "../../config";
import "./style.css";
import WhiteBoxLayout from "../../components/WhiteBoxLayout/WhiteBoxLayout";

function WorkshopSelection() {
  const { setError, reload, setReload, data } = useContext(RegistrationContext);

  const [selectedOptions, setSelectedOptions] = useState(
    workshops.filter((workshop) => {
      return data.length > 0 && data.includes(workshop.id);
    })
  );

  const handleSelect = async (event) => {
    // Reset login error state
    event.preventDefault();
    setError("");
    try {
      // Make a POST request to your authentication endpoint
      const response = await axios.post(`${base_url}/api/progress`, {
        workshop_selection: selectedOptions.map((option) => option.id),
      });
      setReload(reload + 1);
    } catch (error) {
      setError("Unexpected error. " + error.response.data.message);
    }
  };

  const toggleOption = (option) => {
    const isSelected = selectedOptions.some(
      (selectedOption) => selectedOption.id === option.id
    );
    if (isSelected) {
      setSelectedOptions(
        selectedOptions.filter(
          (selectedOption) => selectedOption.id !== option.id
        )
      );
    } else {
      if (selectedOptions.length < 3) {
        setSelectedOptions([...selectedOptions, option]);
      } else {
        setError("You can only select 3 Workshops");
      }
    }
  };

  return (
    <WhiteBoxLayout title="Workshop Selection">
      <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
        <h1 className="text-ecmlDark text-2xl font-bold text-left"               >
          Please Select Your Top Three Choices
        </h1>
        <p className="border-2 text-ecmlBrown  border-ecmlOrange py-2 px-4 rounded-lg text-sm">This selection is designed for internal space management purposes only and does not constitute a confirmed booking. Your selection will help us efficiently manage our available spaces."</p>
        <div className=" mx-auto">
          <div style={{ maxWidth: "100%", justifyContent: "center" }}>
            <div style={{ display: "inline-block" }}>
              <div className={"grid"}>
                {workshops.map((workshop) => {
                  const isSelected = selectedOptions
                    .map((a) => a.id)
                    .includes(workshop.id);
                  return (
                    <div
                      key={workshop.id}
                      className={`border rounded-lg p-1 max-h-16 overflow-y-auto text-center py-auto cursor-pointer ${isSelected ? "selected_ws" : "not_selected_ws"
                        }`}
                      onClick={() => toggleOption(workshop)}
                    >
                      <div className="flex justify-between items-center h-full">
                        <div className="text-xs">{workshop.name}</div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <form
          method="POST"
          className="space-y-4 md:space-y-6"
          onSubmit={handleSelect}
        >
          <div className="w-full flex justify-center">
            <button className="button" type="submit">
              Next
            </button>
          </div>
        </form>
      </div>
    </WhiteBoxLayout>
  );
}

export default WorkshopSelection;
