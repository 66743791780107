import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { base_url } from "../../config";
import RegistrationContext from "../../contexts/RegistrationContext";
import AddToCart from "../../components/AddToCart/AddToCart";
import { FileSearch, Info } from "lucide-react";
import {
  FileText,
  LucideScanBarcode,
  Shapes,
  ShoppingBag,
  Trash2,
} from "lucide-react";
import WhiteBoxLayout from "../../components/WhiteBoxLayout/WhiteBoxLayout";

const YesNo = ({ title, name, state, handler }) => {
  return (
    <>
      <div className="text-sm">
        {" "}
        <label htmlFor={name}>{title}</label>
      </div>
      <div className="flex gap-8">
        <div className="py-2 flex items-center">
          <input
            id={`${name}-yes`}
            type="radio"
            name={`${name}`}
            value="yes"
            checked={state === "yes"} // Comprueba si la opción "yes" está seleccionada
            onChange={() => handler("yes")} // Maneja el cambio de selección
            className="w-4 h-4 text-amber-500 bg-gray-100 border-gray-300 rounded focus:ring-amber-500"
            required
          />
          <label
            htmlFor={`${name}-yes`}
            className="ms-2 text-sm font-medium text-gray-900"
          >
            Yes
          </label>
        </div>
        <div className="py-2 flex items-center">
          <input
            id={`${name}-no`}
            type="radio"
            name={`${name}`}
            value="no"
            checked={state === "no"} // Comprueba si la opción "no" está seleccionada
            onChange={() => handler("no")} // Maneja el cambio de selección
            className="w-4 h-4 text-amber-500 bg-gray-100 border-gray-300 rounded focus:ring-amber-500"
            required
          />
          <label
            htmlFor={`${name}-no`}
            className="ms-2 text-sm font-medium text-gray-900"
          >
            No
          </label>
        </div>
      </div>
    </>
  );
};

function Extras() {
  const {
    setError,
    reload,
    setReload,
    data,
    setStatus,
    setCart,
    setPaperOpen,
    paperSelected,
    type,
  } = useContext(RegistrationContext);

  const [visa, setVisa] = useState(data.need_visa);
  const [recruited, setRecruited] = useState(data.sponsors_recruitment);
  const [dietary, setDietary] = useState(data.dietary_requirements);
  const [cut, setCut] = useState(data.t_shirt_cut);
  const [size, setSize] = useState(data.t_shirt_size);
  const [farewell, setFarewell] = useState(data.farewell);
  const [poster, setPoster] = useState(data.poster);
  const [shownPaper, setShownPaper] = useState(data.paper_selected);
  const [animation, setAnimation] = useState(false);
  const [participationGalaDinner, setParticipationGalaDinner] = useState(
    data.gala_dinner
  );
  const [participationWomenLunch, setParticipationWomenLunch] = useState(
    data.women_lunch
  );
  const [discountCode, setDiscountCode] = useState(data.discount_code);
  const [codeValidMessage, setCodeValidMessage] = useState("");
  const [codeErrorMessage, setCodeErrorMessage] = useState("");
  const [codeValidated, setCodeValidated] = useState(
    data.discount_code !== "" && data.discount_code !== null
  );
  const [file, setFile] = useState(null);
  const [backendFile, setBackendFile] = useState(data.file);
  const colors = false;
  useEffect(() => {
    setShownPaper(paperSelected);
  }, [paperSelected]);

  useEffect(() => {
    setShownPaper(data.paper_selected);
  }, [data.paper_selected]);

  const handleClickGalaDinner = async (event) => {
    event.preventDefault();
    setError("");
    try {
      const response = await axios.post(`${base_url}/api/add_item`, {
        dinner: 1,
        farewell: null,
        code: null,
        poster: null,
      });
      setStatus(
        "Item added to cart\nTo review or remove items from your basket, please click the basket icon in the top right corner. "
      );
      setCart(response.data.cart);
    } catch (error) {
      // setError("Unexpected error. " + error.response.data.message);
    }
  };

  const handleRemoveCode = async () => {
    try {
      const response = await axios.post(`${base_url}/api/remove_code`, {});
      setStatus("Code Removed");
      setCodeValidMessage("");
      setCodeErrorMessage("");
      setDiscountCode("");
      setCart(response.data.cart);
      setCodeValidated(false);
    } catch (error) {
      setCodeValidMessage("");
      setCodeValidated(false);
    }
  };

  const handleClickDiscountCode = async (event) => {
    event.preventDefault();
    setError("");
    setDiscountCode(event.target.value);

    if (event.target.value.length != 13) return null;
    try {
      const response = await axios.post(`${base_url}/api/add_item`, {
        dinner: null,
        farewell: null,
        code: event.target.value,
        poster: null,
      });

      setCodeValidMessage("Code applied successfully");
      setCodeErrorMessage("");
      setCart(response.data.cart);
      setAnimation(true);
      setCodeValidated(true);
    } catch (error) {
      setCodeErrorMessage(error.response.data.message);
      setCodeValidMessage("");
      setCodeValidated(false);
    }
  };

  const handleClickFarewellEvent = async (event) => {
    event.preventDefault();
    setError("");
    try {
      const response = await axios.post(`${base_url}/api/add_item`, {
        dinner: null,
        farewell: 1,
        code: null,
        poster: null,
      });
      setStatus(
        "Item added to cart\nTo review or remove items from your basket, please click the basket icon in the top right corner. "
      );
      setFarewell("yes");
      setAnimation(true);
      setCart(response.data.cart);
    } catch (error) {
      // setError("Unexpected error. " + error.response.data.message);
    }
  };

  const handleClickPosterEvent = async (event) => {
    event.preventDefault();
    setError("");
    try {
      const response = await axios.post(`${base_url}/api/add_item`, {
        dinner: null,
        farewell: null,
        code: null,
        poster: 1,
      });
      setStatus(
        "Item added to cart\nTo review or remove items from your basket, please click the basket icon in the top right corner. "
      );
      setPoster("yes");
      setAnimation(true);
      setCart(response.data.cart);
    } catch (error) {
      // setError("Unexpected error. " + error.response.data.message);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError("");

    const formData = new FormData();
    formData.append("need_visa", visa);
    formData.append("sponsors_recruitment", recruited);
    formData.append("dietary_requirements", dietary);
    formData.append("t_shirt_cut", cut);
    formData.append("t_shirt_size", size);
    formData.append("gala_dinner", participationGalaDinner);
    formData.append("women_lunch", participationWomenLunch);
    formData.append("discount_code", discountCode || "");
    formData.append("paper_selected", paperSelected);
    formData.append("file", file);

    try {
      const response = await axios.post(`${base_url}/api/progress`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setReload(reload + 1);
    } catch (error) {
      setError("Unexpected error. " + error.response.data.message);
    }
  };

  const ECMLTooltip = ({ text, tooltip }) => {
    return (
      <div className="flex items-center gap-2">
        <div>{text}</div>
        <div>
          <span title={tooltip}>
            <Info className="text-blue-800" size="18px" />
          </span>
        </div>
      </div>
    );
  };

  const DietaryRequirements = () => {
    return (
      <div className={`w-full flex flex-col ${colors && "bg-amber-200"}`}>
        {/* Dietary requirements container  */}
        <div className="w-full">
          <div className="flex justify-between items-center">
            <div>
              <label
                htmlFor="dietary"
                className="block  text-sm font-medium text-gray-900"
              >
                Dietary requirements:{" "}
              </label>
            </div>
            <div className="w-1/2">
              <select
                id="dietary"
                name="dietary-requirements"
                required
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-amber-500 focus:border-amber-500 block w-full p-2.5"
                defaultValue={dietary}
                onChange={(e) => setDietary(e.target.value)}
              >
                <option value="Regular">Regular</option>
                <option value="Vegetarian">Vegetarian</option>
                <option value="Vegan">Vegan</option>
                <option value="Gluten-free">Gluten-free</option>
                <option value="Lactose-free">Lactose-free</option>
                <option value="Kosher">Kosher</option>
                <option value="Halal">Halal</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const InvitationLetter = () => {
    return (
      <div
        className={`w-full flex justify-between items-center mb-2 ${
          colors && "bg-green-200"
        }`}
      >
        <YesNo
          title="Do you require an invitation letter for a visa?"
          name="visa"
          state={visa}
          handler={setVisa}
        />
      </div>
    );
  };

  const SponsorRequirement = () => {
    return (
      <div
        className={`w-full flex justify-between items-center mb-2 ${
          colors && "bg-blue-200"
        }`}
      >
        <YesNo
          title="Would you like to be considered for recruitment opportunities with our sponsors?"
          name="recruited"
          state={recruited}
          handler={setRecruited}
        />
      </div>
    );
  };

  const GalaDinner = () => {
    return (
      <div
        className={`w-full flex justify-between items-center mb-2 ${
          colors && "bg-purple-200"
        }`}
      >
        <YesNo
          title="Are you planning to attend the gala dinner on Wednesday?"
          name="gala-dinner"
          state={participationGalaDinner}
          handler={(value) => setParticipationGalaDinner(value)}
        />
      </div>
    );
  };

  const WomenLunch = () => {
    return (
      <div
        className={`w-full flex justify-between items-center mb-2 ${
          colors && "bg-teal-500"
        }`}
      >
        <YesNo
          title="Would you like to be contacted regarding a special women-only event?"
          name="women-lunch"
          state={participationWomenLunch}
          handler={(value) => setParticipationWomenLunch(value)}
        />
      </div>
    );
  };

  const SponsorRequirementFile = () => {
    const cutFileName = (name) => {
      return name.length > 40 ? name.substring(0, 40) + "..." : name;
    };

    if (recruited !== "yes") return null;
    return (
      <div className={`text-sm w-full ${colors && "bg-purple-200"}`}>
        <div className="mb-2">Share your CV with our sponsors</div>
        <div className="flex justify-center">
          {file === null && backendFile === null && (
            <input
              type="file"
              id="file"
              name="file"
              accept=".pdf"
              onChange={(e) => {
                const file = e.target.files[0];
                if (file && file.size > 4 * 1024 * 1024) {
                  // 4MB
                  setError("File too large. Max size is 4MB");
                  e.target.value = "";
                } else {
                  setFile(file);
                }
              }}
            />
          )}
          {file === null && backendFile !== null && (
            <>
              <div className="mr-2">{cutFileName(backendFile)}</div>
              <div className=" inset-y-0 right-0 pr-3  flex items-center cursor-pointer text-gray-500 hover:text-red-600">
                <Trash2 onClick={() => setBackendFile(null)} size="16" />
              </div>
            </>
          )}
          {file && (
            <>
              <div className="mr-2">{cutFileName(file.name)}</div>
              <div className=" inset-y-0 right-0 pr-3  flex items-center cursor-pointer text-gray-500 hover:text-red-600">
                <Trash2 onClick={() => setFile(null)} size="16" />
              </div>
            </>
          )}
        </div>
      </div>
    );
  };

  const TShirtSelection = () => {
    return (
      <div className={`w-full ${colors && "bg-yellow-200"}`}>
        {/* T-shirt container */}
        <div className="w-full flex items-center ">
          <div className="w-1/2 leading-tight tracking-tight text-gray-900 text-sm ">
            T shirt: (
            <a
              href="images/male-tshirts-spec.jpg"
              target="_blank"
              className="text-blue-900 underline"
            >
              size chart
            </a>
            )
          </div>
          <div className="w-1/4">
            <select
              id="cut"
              name="cut"
              defaultValue={cut}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-amber-500 focus:border-amber-500 block w-full p-2.5"
              onChange={(e) => setCut(e.target.value)}
              required
            >
              <option value="">-- Cut --</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </select>
          </div>
          <div className="w-1/4">
            <select
              id="size"
              name="size"
              defaultValue={size}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-amber-500 focus:border-amber-500 block w-full p-2.5"
              onChange={(e) => setSize(e.target.value)}
              required
            >
              <option value="">-- Size --</option>
              <option value="S">S</option>
              <option value="M">M</option>
              <option value="L">L</option>
              <option value="XL">XL</option>
              <option value="XXL">XXL</option>
              <option value="3XL">3XL</option>
            </select>
          </div>
        </div>
        {/* End T-shirt container */}
      </div>
    );
  };

  const FarewellEvent = () => {
    return (
      <div className={`flex w-full items-center ${colors && "bg-cyan-400"}`}>
        <div className="grow">
          <div className="block  text-sm font-medium text-gray-900">
            <ECMLTooltip
              text={"Farewell Event on Friday (20€):"}
              tooltip={
                "There will be an informal event in the city center on Friday evening."
              }
            />
          </div>
        </div>

        <div className=" justify-end">
          <AddToCart
            onClickHanlder={(e) => handleClickFarewellEvent(e)}
            disabled={farewell === "yes"}
          />
        </div>
      </div>
    );
  };

  const Acompanying = () => {
    return (
      <div className={`flex w-full items-center ${colors && "bg-cyan-400"}`}>
        <div className="grow">
          <label
            htmlFor="accompanying-person"
            className="block  text-sm font-medium text-gray-900"
          >
            Accompanying person for Gala dinner (80€) :{" "}
          </label>
        </div>

        <div className="justify-end">
          <div>
            <AddToCart onClickHanlder={(e) => handleClickGalaDinner(e)} />
          </div>
        </div>
      </div>
    );
  };

  const Poster = () => {
    return (
      <div className={`flex w-full items-center ${colors && "bg-cyan-400"}`}>
        <div className="grow">
          <div className="block  text-sm font-medium text-gray-900 ">
            <ECMLTooltip
              text={"Do you want the organizers to print the poster ? (20€):"}
              tooltip={
                "After your payment, the organizers will contact you with instructions on how to send your poster for printing in advance in Vilnius. Your poster will be ready for the poster session."
              }
            />
          </div>
        </div>

        <div className=" justify-end">
          <AddToCart
            onClickHanlder={(e) => handleClickPosterEvent(e)}
            disabled={poster === "yes"}
          />
        </div>
      </div>
    );
  };

  const Assistance = () => {
    return (
      <div className={`w-full flex flex-col ${colors && "bg-amber-200"}`}>
        {/* Dietary requirements container  */}
        <div className="w-full">
          <div className="flex justify-between items-center">
            <div>
              <label
                htmlFor="dietary"
                className="block  text-sm font-medium text-gray-900"
              >
                Select your day of assitance:{" "}
              </label>
            </div>
            <div className="w-1/2">
              <select
                id="dietary"
                name="dietary-requirements"
                required
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-amber-500 focus:border-amber-500 block w-full p-2.5"
                defaultValue={dietary}
                onChange={(e) => setDietary(e.target.value)}
              >
                <option value="9">September 9</option>
                <option value="10">September 10</option>
                <option value="11">September 11</option>
                <option value="12">September 12</option>
                <option value="13">September 13</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <WhiteBoxLayout title="Extras">
      <div className="p-6 space-y-4 md:space-y-6 sm:p-8 ">
        <form
          className="space-y-4 md:space-y-6"
          method="POST"
          onSubmit={handleSubmit}
        >
          <div className="flex md:gap-8 " style={{ display: "block" }}>
            <div>
              <div className="w-full">
                <div className="py-2 font-bold leading-tight tracking-tight text-gray-900 text-xl flex gap-2 border-b-slate-200 border-b mb-2">
                  <Shapes /> Additional Requests & Preferences
                </div>
                <div className="flex flex-col sm:flex-row sm:gap-10 md:gap-32 items-center pb-0 sm:pb-2">
                  <InvitationLetter />
                  <DietaryRequirements />
                </div>
                <div className="flex flex-col sm:flex-row sm:gap-10 md:gap-32 items-center  pb-0 sm:pb-2">
                  <SponsorRequirement />
                  <TShirtSelection />
                </div>
                <div className="flex  flex-col sm:flex-row sm:gap-10 md:gap-32 items-center  pb-0 sm:pb-2">
                  <SponsorRequirementFile />
                  <div className="w-full"> </div>
                </div>
              </div>
            </div>
            <div className="mt-10">
              <div className="w-full">
                <div className="py-2 font-bold leading-tight tracking-tight text-gray-900 text-xl flex gap-2 border-b-slate-200 border-b mb-2">
                  <ShoppingBag /> Event Attendance and Preferences
                </div>
                <div className="flex flex-col sm:flex-row sm:gap-10 md:gap-32 items-center  pb-0 sm:pb-2">
                  <GalaDinner />
                  <FarewellEvent />
                </div>
                <div className="flex flex-col sm:flex-row sm:gap-10 md:gap-32 items-center  pb-0 sm:pb-2">
                  <WomenLunch />
                  <Acompanying />
                </div>
                <div className="flex flex-col sm:flex-row sm:gap-10 md:gap-32 items-center  pb-0 sm:pb-2">
                  {type === "single" ? (
                    <Assistance />
                  ) : (
                    <div className="w-full"></div>
                  )}
                  <Poster />
                </div>
              </div>
              <div className="w-full mt-10">
                <div className="py-2 font-bold leading-tight tracking-tight text-gray-900 text-xl flex gap-2 border-b-slate-200 border-b mb-2">
                  <LucideScanBarcode /> Discount Code
                </div>
                <div className="flex flex-col sm:flex-row sm:gap-10 md:gap-32 ">
                  <div className="w-full flex items-center">
                    <div className="w-1/3">
                      <label
                        htmlFor="dicount-code"
                        className="block mb-2 text-sm font-medium text-gray-900"
                      >
                        Code:{" "}
                      </label>
                    </div>
                    <div className="w-1/3">
                      <input
                        id="dicount-code"
                        name="dicount-code"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-amber-500 focus:border-amber-500 block w-full p-2.5"
                        onChange={(e) => handleClickDiscountCode(e)}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault();
                            handleClickDiscountCode(e);
                          }
                        }}
                        value={discountCode}
                        disabled={codeValidated}
                      ></input>
                    </div>
                    <div>
                      {codeValidated && (
                        <div className=" pl-3  flex items-center cursor-pointer text-gray-500 hover:text-red-600">
                          <Trash2
                            size="16"
                            onClick={() => handleRemoveCode()}
                          />
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="w-full flex items-center">
                    {codeErrorMessage && (
                      <div className="text-red-500 text-sm my-auto font-bold">
                        {codeErrorMessage}
                      </div>
                    )}
                    {codeValidMessage && (
                      <div className="text-green-500 text-sm my-auto font-bold">
                        {codeValidMessage}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="w-full mt-10">
                <div className="py-2 font-bold leading-tight tracking-tight text-gray-900 text-xl flex gap-2 border-b-slate-200 border-b mb-2">
                  <FileText /> Paper Registration
                </div>
                <div className="flex flex-col sm:flex-row sm:gap-10 md:gap-32 ">
                  <div className="w-full flex items-center">
                    <div className="w-1/3">
                      <label
                        htmlFor="paper-registered"
                        className="block mb-2 text-sm font-medium text-gray-900"
                      >
                        Registered paper:{" "}
                      </label>
                    </div>
                    <div className="w-1/3 relative">
                      <input
                        id="paper-registered"
                        name="paper-registered"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-amber-500 focus:border-amber-500 block w-full p-2.5"
                        disabled
                        value={shownPaper || ""}
                      />
                      {shownPaper && (
                        <div className="absolute inset-y-0 right-0 pr-3  flex items-center cursor-pointer text-gray-500 hover:text-red-600">
                          <Trash2 onClick={() => setShownPaper("")} size="16" />
                        </div>
                      )}
                    </div>
                    <div className="w-1/3  flex justify-start pl-2">
                      <div style={{ width: "60px", cursor: "pointer" }}>
                        <div
                          onClick={() => {
                            setPaperOpen(true);
                          }}
                          className={`addcart  flex gap-2 `}
                        >
                          <FileSearch size={18} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-full"> </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full flex justify-center">
            <button className="button" type="submit">
              Next
            </button>
          </div>
        </form>
      </div>
    </WhiteBoxLayout>
  );
}

export default Extras;
