export const base_url = process.env.REACT_APP_API_URL;
export const front_url = process.env.REACT_APP_FRONT_URL;
export const stripe_key = process.env.REACT_APP_STRIPE_KEY;
export const steps = [
  "Registration Type",
  "Personal Data",
  "Workshop Selection",
  "Extras",
  "Payment",
  "Confirmation",
];