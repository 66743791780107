import React, { useContext, useState, useEffect } from "react";
import RegistrationContext from "../../contexts/RegistrationContext";
import "./style.css";
import { FileSearch, CirclePlus } from "lucide-react";
import axios from "axios";
import { base_url } from "../../config";

function SearchPapers() {
  const { setPaperOpen, setPaperSelected } = useContext(RegistrationContext);
  const [filteredPapers, setFilteredPapers] = useState([]);
  const [papers, setPapers] = useState([]);

  useEffect(() => {
    axios.get(`${base_url}/api/papers`).then((response) => {
      setPapers(response.data);
      setFilteredPapers(response.data);
    });
  }, []);
  const paperClicked = (paperId) => {
    setPaperOpen(false);
    setPaperSelected(paperId);
  };

  // function to filter papers based on search input
  const filterPapers = (event) => {
    const searchInput = event.target.value;
    const mfilteredPapers = papers.filter((paper) => {
      return (
        paper.title.toLowerCase().includes(searchInput.toLowerCase()) ||
        paper.id.toString().includes(searchInput.toLowerCase())
      );
    });
    setFilteredPapers([...mfilteredPapers]);
  };

  return (
    <div className="search-papers-form paper-input-container flex flex-col justify-center px-6 py-8 mx-auto h-full lg:py-0 text-left">
      <div className="flex text-white gap-4">
        <div>
          <FileSearch size={30} />
        </div>
        <div
          className="sectionTitle"
          style={{ color: "white", marginBottom: "50px" }}
        >
          Add a Paper to Your Registration
        </div>
      </div>
      <div className="my-8">
        <form className="mx-auto">
          <label
            htmlFor="default-search"
            className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
          >
            Search
          </label>
          <div className="relative">
            <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                aria-hidden="true"
                className="w-4 h-4 text-gray-500 dark:text-gray-400"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 19l-4-4m0-7A7 7 0 111 8a7 7 0 0114 0z"
                ></path>
              </svg>
            </div>
            <input
              type="search"
              id="default-search"
              className="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
              placeholder="Type your paper Title or ID"
              onChange={(event) => filterPapers(event)}
              required
            />
          </div>
        </form>
      </div>
      <div className="w-full h-full bg-white rounded-lg shadow md:mt-0 xl:p-0 overflow-y-auto ">
        <div className="w-full p-6 space-y-4 md:space-y-6 sm:p-8 text-xs">
          {filteredPapers.map((paper, index) => {
            return (
              <div key={index} className="flex flex-col gap-4 ">
                <div className="flex gap-2">
                  <div className="tags-wrapper flex gap-2">
                    <div
                      className="border border-gray-500 px-2 py-2 rounded-md h-8 text-center"
                      style={{ width: "55px" }}
                    >
                      {paper.type}
                    </div>
                    <div
                      className="bg-ecmlBrown text-white px-2 py-2 rounded-md h-8 text-center"
                      style={{ width: "55px" }}
                    >
                      {paper.id}
                    </div>
                  </div>

                  <div className="text-black flex-grow">{paper.title}</div>
                  <div className="text-black cursor-pointer">
                    {" "}
                    <CirclePlus
                      size="14"
                      onClick={() => paperClicked(`${paper.type}-${paper.id}`)}
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
export default SearchPapers;
