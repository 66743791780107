import React from "react";

export default function App(props) {
  return (
    <div className="h-full flex flex-col relative">
      <div className="px-8 py-4">
        <h1 className="flex gap-4 text-xl items-center">
          {props.icon} {props.title}
        </h1>
      </div>
      <div className="p-8 grow bg-white">{props.children}</div>
    </div>
  );
}
