// src/contexts/RegistrationContext.js

import React from "react";

const RegistrationContext = React.createContext({
  screen: "loading", // default value
  setScreen: () => {}, // default empty function
  authenticated: false,
  setAuthenticated: () => {},
  error: "",
  setError: () => {},
  status: "",
  setStatus: () => {},
  reload: false,
  setReload: () => {},
  loading: false,
  setLoading: () => {},
  maxStep: "",
  setMaxStep: () => {},
  data: {},
  setData: () => {},
  cartOpen: false,
  setCartOpen: () => {},
  cart: {},
  setCart: () => {},
  cartAdded: false,
  setCartAdded: () => {},
  userInfoOpen: false,
  setUserInfoOpen: () => {},
  email: "",
  setEmail: () => {},
  phase: "",
  setPhase: () => {},
  type: "",
  setType: () => {},
  inCommunication: false,
  setInCommunication: () => {},
  paperOpen: false,
  setPaperOpen: () => {},
  paperSelected: "",
  setPaperSelected: () => {},
  isAdmin: false,
  setIsAdmin: () => {},
  isViewer: false,
  setIsViewer: () => {},
  isImpersonating: false,
  setIsImpersonating: () => {},
  next: null,
  setNext: () => {},
  prices : {},
  SetPrices : () => {},
  deadline : {},
  SetDeadline : () => {},
});

export default RegistrationContext;
