import { useContext, useEffect, useState } from "react";
import RegistrationContext from "./contexts/RegistrationContext";
import setAuthToken from "./authtoken";
import NavBar from "./components/NavBar/NavBar";
import Footer from "./components/Footer/Footer";
import StatusBar from "./components/StatusBar/StatusBar";
import axios from "axios";
import { base_url } from "./config";
import parseAnswer from "./util/parseAnswer";
import NotAuthenticated from "./sections/NotAuthenticated";
import RegularUser from "./sections/RegularUser";
import AdminUser from "./sections/AdminUser";
import ViewerUser from "./sections/ViewerUser";
import Loading from "./pages/Loading/Loading";
import RegistrationComplete from "./sections/RegistrationComplete";

function App() {
  const {
    screen,
    authenticated,
    setAuthenticated,
    reload,
    setScreen,
    setMaxStep,
    setData,
    setError,
    setStatus,
    setReload,
    setCart,
    setEmail,
    setPhase,
    setPaperSelected,
    setIsAdmin,
    setIsViewer,
    loading,
    setLoading,
    setType,
    setIsImpersonating,
    next,
    setNext,
    setPrices,
    setDeadline,
  } = useContext(RegistrationContext);

  const token = localStorage.getItem("token");
  if (token) {
    setAuthenticated(true);
    setAuthToken(token);
  }

  useEffect(() => {
    axios
      .get(`${base_url}/api/progress`)
      .then(async (response) => {
        setScreen(parseAnswer(response));
        setMaxStep(response.data.max_step);
        if (response && response.data.data) {
          setData(response.data.data);
        }
        if (response && response.data.cart) {
          setCart(response.data.cart);
        }
        setEmail(response.data.email);
        setPhase(response.data.phase);
        setType(response.data.type);
        setIsImpersonating(response.data.impersonating);
        if (response && response.data.paper) {
          setPaperSelected(response.data.paper);
        } else {
          setPaperSelected("");
        }
        setPrices(response.data.prices);
        setType(response.data.type);
        setLoading(false);
        setIsAdmin(response.data.role === "admin");
        setIsViewer(response.data.role === "viewer");
        if (next) {
          setScreen(next);
          setNext(null);
        }
      })
      .catch(() => {
        setLoading(false);
        setAuthToken("");

        localStorage.removeItem("token");
        setScreen("login");
        setData({});
        setError("");
        setStatus("");
        setMaxStep("");
        setAuthenticated(false);
      });
  }, [
    reload,
    setScreen,
    setAuthenticated,
    setError,
    setStatus,
    setReload,
    setMaxStep,
    setData,
    setEmail,
    setCart,
    setIsAdmin,
    setPaperSelected,
    setPhase,
    setLoading,
  ]);

  useEffect(() => {
    // Add your code here

    if (!authenticated) {
      async function fetchData() {
        try {
          const response = await axios.post(`${base_url}/api/get_phase`);
          setPhase(response.data.phase);
          setDeadline(response.data.deadline);
        } catch (error) {
          setError("Network error");
        }
      }
      fetchData();
    }
  }, [authenticated, setError, setPhase]);

  useEffect(() => {
    // Add your code here
    setLoading(true);
  }, []);

  const isAdminScreen = screen.startsWith("admin");
  const isViewerScreen = screen.startsWith("viewer");
  if (loading) {
    return <Loading />;
  }
  return (
    <div className="App">
      <div className="nav">
        <NavBar boxed={!isAdminScreen} />
      </div>
      <div>
        <StatusBar />
      </div>
      {!authenticated && <NotAuthenticated />}
      {authenticated && !isAdminScreen && !isViewerScreen && screen !== "Confirmation" && (
        <RegularUser />
      )}
      {authenticated && !isAdminScreen && !isViewerScreen && screen === "Confirmation" && (
        <RegistrationComplete />
      )}
      {authenticated && isAdminScreen && <AdminUser />}
      {authenticated && isViewerScreen && <ViewerUser />}

      <div>
        <Footer />
      </div>
    </div>
  );
}

export default App;
