import React from 'react';
import './style.css';

const SidePanel = (props) => {
    const { isOpen, openCloseHandler } = props;

    return (
        <>
            {isOpen && <div className="overlay" onClick={() => openCloseHandler(false)} ></div>}
            <div className={`side-panel ${isOpen && "open"} `}>
                <div
                    className="button w-24 ml-auto mb-8"
                    onClick={() => openCloseHandler(false)}
                >
                    {" "}
                    Close{" "}
                </div>

                <div className="flex-grow mb-2  overflow-y-hidden">
                    {props.children}
                </div>

            </div>
        </>
    );
}

export default SidePanel;