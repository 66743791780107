import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import RegistrationContext from "../contexts/RegistrationContext";
import { base_url } from "../config";
import countriesData from "../countries.json";
import WhiteBoxLayout from "../components/WhiteBoxLayout/WhiteBoxLayout";

function PersonalData() {
  const { setError, reload, setReload, data, maxStep, screen } =
    useContext(RegistrationContext);

  const [fullName, setFullName] = useState(data.full_name);
  const [Affiliation, setAffiliation] = useState(data.affiliation);
  const [Affiliation2, setAffiliation2] = useState(data.affiliation2);
  const [recipientName, setRecipientName] = useState(data.recipient_name);
  const [institution, setInstitution] = useState(data.institution);
  const [Address1, setAddress1] = useState(data.address1);
  const [Address2, setAddress2] = useState(data.address2);
  const [taxID, setTaxID] = useState(data.tax_id);
  const [country, setCountry] = useState(data.country);
  const [gender, setGender] = useState(data.gender);
  const [registersPublication, setRegistersPublication] = useState(
    data.registers_publication
  );
  const [specialRequirements, setSpecialRequirements] = useState(
    data.special_requirements
  );
  const [check1, setCheck1] = useState(maxStep !== screen);

  const handleSelect = async (event) => {
    // Reset login error state
    event.preventDefault();
    setError("");
    const regex = /^\s*\S+\s+\S+.*$/;

    if (!regex.test(fullName)) {
      setError('Name and surname field must contain at least two words.');
      return;
    }
    if (recipientName && !regex.test(recipientName)) {
      setError('Recipient name field must contain at least two words.');
      return;
    }
    try {
      // Make a POST request to your authentication endpoint
      const response = await axios.post(`${base_url}/api/progress`, {
        full_name: fullName,
        affiliation: Affiliation,
        affiliation2: Affiliation2,
        recipient_name: recipientName,
        institution: institution,
        address1: Address1,
        address2: Address2,
        tax_id: taxID,
        country: country,
        registers_publication: registersPublication,
        gender: gender,
        special_requirements: specialRequirements,
      });
      setReload(reload + 1);
    } catch (error) {
      setError("Unexpected error. " + error.response.data.message);
    }
  };

  const buttons_checked = screen !== maxStep;
  return (
    <WhiteBoxLayout title={"Personal data"}>
      <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
        <form
          method="POST"
          className="space-y-4 md:space-y-6"
          onSubmit={handleSelect}
        >
          <div className="md:flex md:gap-8">
            <div className="w-full">
              <h1 className="py-2 font-bold leading-tight tracking-tight text-gray-900 text-xl ">
                Delegate Info
              </h1>
              <div className="py-2">
                <label
                  htmlFor="name"
                  className="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Name and surname: <span className="text-red-600">*</span>
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5      "
                  placeholder="Your full name"
                  required
                />
              </div>
              <div className="py-2">
                <label
                  htmlFor="Affiliation"
                  className="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Affiliation: <span className="text-red-600">*</span>
                </label>
                <input
                  type="text"
                  id="Affiliation"
                  name="Affiliation"
                  placeholder="Your primary affiliation"
                  value={Affiliation}
                  onChange={(e) => setAffiliation(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5      "
                  required
                />
              </div>
              <div className="py-2">
                <label
                  htmlFor="Affiliation2"
                  className="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Affiliation 2:
                </label>
                <input
                  type="text"
                  id="Affiliation2"
                  name="Affiliation2"
                  placeholder="Your secondary affiliation"
                  value={Affiliation2}
                  onChange={(e) => setAffiliation2(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5      "
                />
              </div>

              <div className="items-center">
                <div className="text-sm my-2">
                  {" "}
                  <label htmlFor="gender">
                    Gender: <span className="text-red-600">*</span>
                  </label>
                </div>

                <div className="flex gap-8 bg-gray-50 border border-gray-300 rounded-lg px-2.5 py-0.5 mb-4">
                  <div className="py-2 flex items-center">
                    <input
                      id="gender-male"
                      type="radio"
                      name="gender"
                      value="male"
                      checked={gender === "male"} // Comprueba si la opción "yes" está seleccionada
                      onChange={() => setGender("male")} // Maneja el cambio de selección
                      className="w-4 h-4 text-amber-500 bg-gray-100 border-gray-300 rounded focus:ring-amber-500"
                      required
                    />
                    <label
                      htmlFor="gender-male"
                      className="ms-2 text-sm font-medium text-gray-900"
                    >
                      Male
                    </label>
                  </div>
                  <div className="py-2 flex items-center">
                    <input
                      id="gender-female"
                      type="radio"
                      name="gender"
                      value="female"
                      checked={gender === "female"}
                      onChange={() => setGender("female")}
                      className="w-4 h-4 text-amber-500 bg-gray-100 border-gray-300 rounded focus:ring-amber-500"
                      required
                    />
                    <label
                      htmlFor="gender-female"
                      className="ms-2 text-sm font-medium text-gray-900"
                    >
                      Female
                    </label>
                  </div>
                  <div className="py-2 flex items-center">
                    <input
                      id="gender-ns"
                      type="radio"
                      name="gender"
                      value="ns"
                      checked={gender === "ns"} // Comprueba si la opción "yes" está seleccionada
                      onChange={() => setGender("ns")} // Maneja el cambio de selección
                      className="w-4 h-4 text-amber-500 bg-gray-100 border-gray-300 rounded focus:ring-amber-500"
                      required
                    />
                    <label
                      htmlFor="gender-ns"
                      className="ms-2 text-sm font-medium text-gray-900"
                    >
                      Prefer not to say / Not listed
                    </label>
                  </div>
                </div>
              </div>

              <div className="items-center">
                <div className="text-sm my-2">
                  <label htmlFor="publication">
                    Are you an author of an accepted publication?{" "}
                    <span className="text-red-600">*</span>
                  </label>
                </div>

                <div className="flex gap-8 bg-gray-50 border border-gray-300 rounded-lg px-2.5 py-0.5 mb-4">
                  <div className="py-2 flex items-center">
                    <input
                      id="publication-yes"
                      type="radio"
                      name="publication"
                      value="yes"
                      checked={registersPublication === "yes"} // Comprueba si la opción "yes" está seleccionada
                      onChange={() => setRegistersPublication("yes")} // Maneja el cambio de selección
                      className="w-4 h-4 text-amber-500 bg-gray-100 border-gray-300 rounded focus:ring-amber-500"
                      required
                    />
                    <label
                      htmlFor="publication-yes"
                      className="ms-2 text-sm font-medium text-gray-900"
                    >
                      Yes
                    </label>
                  </div>
                  <div className="py-2 flex items-center">
                    <input
                      id="publication-no"
                      type="radio"
                      name="publication"
                      value="no"
                      checked={registersPublication === "no"} // Comprueba si la opción "no" está seleccionada
                      onChange={() => setRegistersPublication("no")} // Maneja el cambio de selección
                      className="w-4 h-4 text-amber-500 bg-gray-100 border-gray-300 rounded focus:ring-amber-500"
                      required
                    />
                    <label
                      htmlFor="publication-no"
                      className="ms-2 text-sm font-medium text-gray-900"
                    >
                      No
                    </label>
                  </div>
                </div>
              </div>

              <div className="pb-2">
                <label
                  htmlFor="specialRequirements"
                  className="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Disabilities/Special Requirements
                </label>
                <input
                  type="text"
                  id="specialRequirements"
                  name="specialRequirements"
                  placeholder="If you have any special requirements, please let us know."
                  value={specialRequirements}
                  onChange={(e) => setSpecialRequirements(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5      "
                />
              </div>
              <div className="py-2 flex items-center">
                <input
                  id="default-checkbox"
                  type="checkbox"
                  value=""
                  className="w-4 h-4 text-amber-500 bg-gray-100 border-gray-300 rounded focus:ring-amber-500"
                  required
                  onClick={() => setCheck1(!check1)}
                  checked={check1}
                />
                <p
                  htmlFor="default-checkbox"
                  className="ms-2 text-sm font-medium text-blue-900 "
                >
                  I acknowledge and accept the policy published at <br />{" "}
                  <a
                    style={{ textDecoration: "underline" }}
                    target="_blank"
                    href="https://ecmlpkdd.org/2024/attending-registration/"
                    rel="noreferrer"
                  >
                    {" "}
                    https://ecmlpkdd.org/2024/attending-registration/{" "}
                  </a>{" "}
                  <span className="text-red-600">*</span>
                </p>
              </div>
            </div>
            <div className="w-full">
              <h1 className="py-2 font-bold leading-tight tracking-tight text-gray-900 text-xl ">
                Billing Info
              </h1>
              <div className="py-2">
                <label
                  htmlFor="recipientName"
                  className="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Recipient name:
                </label>
                <input
                  type="text"
                  id="recipientName"
                  name="recipientName"
                  value={recipientName}
                  onChange={(e) => setRecipientName(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5      "
                  placeholder="Optional name for the invoice recipient"
                />
              </div>
              <div className="py-2">
                <label
                  htmlFor="institution"
                  className="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Institution/Organization name
                </label>
                <input
                  type="text"
                  id="institution"
                  name="institution"
                  value={institution}
                  onChange={(e) => setInstitution(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5      "
                  placeholder="Optionally provide institution name or organization"
                />
              </div>
              <div className="py-2">
                <label
                  htmlFor="Address1"
                  className="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Address 1: <span className="text-red-600">*</span>
                </label>
                <input
                  type="text"
                  id="Address1"
                  name="Address1"
                  value={Address1}
                  onChange={(e) => setAddress1(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5      "
                  placeholder="Your billing address, first line"
                  required
                />
              </div>
              <div className="py-2">
                <label
                  htmlFor="Address1"
                  className="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Address 2:
                </label>
                <input
                  type="text"
                  id="Address2"
                  name="Address2"
                  value={Address2}
                  onChange={(e) => setAddress2(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5      "
                  placeholder="Your billing address, second line"
                />
              </div>
              <div className="py-2">
                <label
                  htmlFor="countries"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Country: <span className="text-red-600">*</span>
                </label>
                <select
                  id="countries"
                  name="country"
                  onChange={(e) => setCountry(e.target.value)}
                  defaultValue={country}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-amber-500 focus:border-amber-500 block w-full p-2.5"
                  required
                >
                  <option value="">-- Select country --</option>
                  {countriesData.map((countr) => (
                    <option value={countr.code}>{countr.name}</option>
                  ))}
                </select>
              </div>
              <div className="py-2">
                <label
                  htmlFor="Address1"
                  className="block mb-2 text-sm font-medium text-gray-900 "
                >
                  VAT:
                </label>
                <input
                  type="text"
                  id="taxID"
                  name="taxID"
                  value={taxID}
                  onChange={(e) => setTaxID(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5      "
                  placeholder="Provide your tax id to appear on the invoice"
                />
              </div>
            </div>
          </div>
          <div className="w-full flex justify-center">
            <button className="button" type="submit">
              Next
            </button>
          </div>
        </form>
      </div>
    </WhiteBoxLayout>
  );
}

export default PersonalData;
