import React, { useContext, useState } from "react";
import "./style.css";
import { ReceiptEuro } from "lucide-react";

import { useEffect } from "react";
import RegistrationContext from "../../contexts/RegistrationContext";

import axios from "axios";
import { base_url } from "../../config";

function Summary() {
  const { data } = useContext(RegistrationContext);
  const [billingHistory,setBillingHistory] = useState([
  ]);

  useEffect(() => {
    window.history.replaceState({}, "", "/");
  }, []);
 

  const downloadInvoice = async (invoiceId) => {
    try {
      const response = await axios.get(`${base_url}/api/get_invoice/${invoiceId}`, {
        responseType: "blob",
      });
      const pdfBlob = new Blob([response.data], { type: "application/pdf" });

      const url = window.URL.createObjectURL(pdfBlob);
      const tempLink = document.createElement("a");
      tempLink.href = url;
      tempLink.setAttribute("download", `ECML-${data.id}.pdf`); // Set the desired filename for the downloaded file

      // Append the <a> element to the body and click it to trigger the download
      document.body.appendChild(tempLink);
      tempLink.click();

      // Clean up the temporary elements and URL
      document.body.removeChild(tempLink);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  useEffect(() => {
    window.history.replaceState({}, "", "/");

    const fetchInvoices = async () => {
      try {
        await axios.post(`${base_url}/api/get_billing`).then((response) => {
          setBillingHistory(response.data);
        });
      } catch (error) {
        console.error("Communication Error", error);
      }
    };
    fetchInvoices();

  }, []);

 
  
  return (

    <div className="flex flex-col h-full grow billing">
      <div
        style={{ maxWidth: "1152px", width: "100%" }}
        className="flex justify-start flex-col h-full items-center mx-auto lg:py-0 text-left"
      >
        <div
          style={{ maxWidth: "1152px", width: "100%", maxHeight: "100%" }}
          className="bg-white md:mt-0 sm:max-w-md xl:p-0  "
        >
          <div>
            <div className="flex flex-col h-full bg-white rounded-md">
              <div
                style={{ maxWidth: "1152px", width: "100%" }}
                className="flex justify-center flex-col h-full items-center mx-auto lg:py-0 text-left"
              >
                <div className="w-full p-6 space-y-4 md:space-y-6 sm:p-8 summary-page-content">
                  <h1 className="text-ecmlBrown  text-center px-10 py-4 rounded-md text-2xl underline">
                    Billing
                  </h1>

                  <div className="summary-card">
                    <div className="history-title pt-2 font-bold leading-tight tracking-tight text-gray-900 text-xl flex gap-2">
                      <ReceiptEuro /> History
                    </div>

                    {/* HISTORY */}
                    <div>
                      {billingHistory.map((order, index) => (
                        <div key={index} className="flex mb-4 flex-col">
                          <hr className="mt-2 mb-4" />

                          <div className="flex justify-between flex-col sm:flex-row">
                            <div className="mb-4">{order.order}</div>

                            <div className="flex gap-2">
                              <div
                                className="button"
                                onClick={() => downloadInvoice(order.invoiceId)}
                              >
                                Get Invoice
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col h-full  mt-8">
              <div
                style={{
                  maxWidth: "1152px",
                  width: "100%",
                  minHeight: "150px",
                  backgroundImage: `url('https://d1u5q3vcv714vh.cloudfront.net/eyJidWNrZXQiOiJlY21scGtkZC1zdG9yYWdlIiwia2V5IjoiYmFja2dyb3VuZF83ZDc0ZGUwYTZjLndlYnAifQ==')`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  borderRadius: "10px",
                }}
                className=" h-full  mx-auto lg:py-0 text-left"
              >
                <div
                  style={{
                    color: "white",
                    fontSize: "50px",
                    fontWeight: "900",
                    paddingLeft: "40px",
                  }}
                >
                  See you soon in Vilnius :)
                </div>
                <div
                  style={{
                    color: "white",
                    fontSize: "20px",
                    fontWeight: "90",
                    paddingLeft: "40px",
                  }}
                >
                  For travel information and exclusive accommodation deals, be
                  sure to visit our{" "}
                  <a
                    style={{ textDecoration: "underline" }}
                    target="_blank"
                    href="https://ecmlpkdd.org/2024/"
                    rel="noreferrer"
                  >
                    website
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Summary;
