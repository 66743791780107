import React, { useState } from "react";
import axios from "axios";
import { useContext } from "react";
import RegistrationContext from "../../contexts/RegistrationContext";
import { base_url } from "../../config";
import "./style.css";

function LoginForm() {
  const [formEmail, setFormEmail] = useState("");
  const [password, setPassword] = useState("");

  const { setScreen, setAuthenticated, setError, reload, setReload, setEmail, email } =
    useContext(RegistrationContext);
  const handleSubmit = async (event) => {
    event.preventDefault();

    // Reset login error state
    setError("");

    try {
      // Make a POST request to your authentication endpoint
      const response = await axios.post(`${base_url}/api/login`, {
        email: formEmail,
        password: password,
      });

      setAuthenticated(true);
      localStorage.setItem("token", response.data.access_token);
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${response.data.access_token}`;
      setReload(reload + 1);
      setEmail(formEmail);
    } catch (error) {
      setError("Login failed. " + error.response.data.message);
    }
  };
  return (
    <>
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-full lg:py-0 text-left">
        <div className="w-full bg-white rounded-lg shadow  md:mt-0 sm:max-w-md xl:p-0  ">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl ">
              Login to your account
            </h1>
            <form
              className="space-y-4 md:space-y-6 w-full"
              action="#"
              onSubmit={handleSubmit}
            >
              <div>
                <label
                  htmlFor="email"
                  className="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Your email
                </label>
                <input
                  type="text"
                  name="email"
                  id="email"
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5      "
                  placeholder="name@company.com"
                  value={formEmail}
                  onChange={(e) => setFormEmail(e.target.value)}
                  required
                />
              </div>
              <div>
                <label
                  html="password"
                  className="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Password
                </label>
                <input
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  type="password"
                  name="password"
                  id="password"
                  placeholder="••••••••"
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5      "
                />
              </div>
              <div className="flex items-center justify-between">
                <button className="button" type="submit">
                  Login
                </button>
                <button
                  href="#"
                  className="text-sm font-medium text-primary-600 hover:underline "
                  onClick={() => setScreen("forgotpass")}
                >
                  Forgot Password ?
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default LoginForm;
