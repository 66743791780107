import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { useContext } from "react";
import RegistrationContext from "../../contexts/RegistrationContext";
import { base_url, front_url } from "../../config";
import axios from "axios";
import img from "./stripe.png";
import parseAnswer from "../../util/parseAnswer";

export default function CheckoutForm() {
  const stripe = useStripe();
  const elements = useElements();
  const {
    setScreen,
    setReload,
    setError,
    reload,
    cart,
    setCart,
    setEmail,
    setPhase,
    setMaxStep,
    setData,
  } = useContext(RegistrationContext);
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const paid = async (paymentIntent) => {
    setError("");
    let succeeded = false;
    try {
      // Make a POST request to your authentication endpoint
      const response = await axios.post(
        `${base_url}/api/progress`,
        paymentIntent
      );
      setScreen("Confirmation");
      succeeded = true;
    } catch (error) {
      setError("Unexpected error. " + error.response.data.message);
    }
    try {
      // Make a POST request to your authentication endpoint
      const response = await axios.get(`${base_url}/api/progress`, {});
      setMaxStep(response.data.max_step);
      if (response && response.data.data) {
        setData(response.data.data);
      }
      if (response && response.data.cart) {
        setCart(response.data.cart);
      }
      setEmail(response.data.email);
      setPhase(response.data.phase);
    } catch (error) {
      setError("Unexpected error. " + error.response.data.message);
    }
  };

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          paid(paymentIntent);

          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: base_url + "/api/payment/",
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occurred.");
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: "tabs",
  };

  return (
    <>
      {window.location.search === "" && (
        <>
          <form id="payment-form" onSubmit={handleSubmit}>
            <PaymentElement
              id="payment-element"
              options={paymentElementOptions}
            />
            <button disabled={isLoading || !stripe || !elements} id="submit">
              <span id="button-text">
                {isLoading ? (
                  <div className="spinner" id="spinner"></div>
                ) : (
                  "Pay now " + cart.total_price + " €"
                )}
              </span>
            </button>

            {/* Show any error or success messages */}
            {message && <div id="payment-message">{message}</div>}
          </form>
          <div className="flex justify-center items-center mt-8 mx-auto">
            <img src={img} width="120px" alt="stripe" className="stripe" />
          </div>
        </>
      )}
    </>
  );
}
