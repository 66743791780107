import React, { useContext, useState } from "react";
import RegistrationContext from "../../contexts/RegistrationContext";
import Card from "../../components/Card/Card";
import Logo from "../../assets/Logo";
import axios from "axios";
import { base_url } from "../../config";
import "./style.css";
import WhiteBoxLayout from "../../components/WhiteBoxLayout/WhiteBoxLayout";

function RegistrationOption(props) {
  const { handler } = props;
  return (
    <div className="reg_container">
      <div className="flex-container">
        <div style={{ maxWidth: "300px", width: "100%" }}>
          <div className="w-full basis1 cursor-pointer inline-flex">
            <div onClick={handler}>
              <Card
                Logo={props.Logo}
                title={props.title}
                subtitle={props.subtitle}
              />
            </div>
          </div>
        </div>
        <div className="basis2 flex flex-col justify-between">
          <div className="content">{props.children}</div>
          <div> </div>
          <div className=""></div>
        </div>
      </div>
    </div>
  );
}

function RegistrationType() {
  const {
    setError,
    reload,
    setReload,
    phase,
    inCommunication,
    setInCommunication,
    prices,
  } = useContext(RegistrationContext);
  const Logo1 = () => <Logo width={100} height={100} color={"#444040"} />;
  const Logo2 = () => <Logo width={100} height={100} color={"#ff9900"} />;
  const Logo3 = () => <Logo width={100} height={100} color={"gray"} />;
  const { standardPrice, studentPrice, singlePrice } = {
    standardPrice: prices.standard,
    studentPrice: prices.student,
    singlePrice: prices.single,
  };

  const handleSelect = async (selected_type) => {
    if (inCommunication) return;
    setInCommunication(true);
    // Reset login error state
    setError("");

    try {
      // Make a POST request to your authentication endpoint
      const response = await axios.post(`${base_url}/api/progress`, {
        type: selected_type,
      });
      setReload(reload + 1);
    } catch (error) {
      setError("Login failed. " + error.response.data.message);
    }
    setInCommunication(false);
  };

  return (
    <WhiteBoxLayout title="Select your registration Type" gray={true}>
      <div style={{ maxHeight: "100%" }}>
        <div className="">
          <RegistrationOption
            Logo={Logo1}
            title="Delegate Registration "
            subtitle={standardPrice + "€"}
            handler={() => handleSelect("standard")}
          >
            <div className="lora">
              <b>What's included:</b>
              <ul className="list-disc list-inside">
                <li>
                  All sessions of all tracks (research and ADS, journal, demo),
                  tutorials, workshops, discovery challenges and poster
                  presentations.
                </li>
                <li>Lunch and Coffee breaks.</li>
                <li>Ticket to the Welcome Reception and Conference Gala.</li>
                <li>Conference bag.</li>
              </ul>
            </div>
          </RegistrationOption>
        </div>
        <div>
          <RegistrationOption
            Logo={Logo2}
            title="Student Registration"
            subtitle={studentPrice + "€"}
            handler={() => handleSelect("student")}
          >
            <div className="lora">
              <b>What's included:</b>
              <ul className="list-disc list-inside">
                <li>
                  All sessions of all tracks (research and ADS, journal, demo),
                  tutorials, workshops, discovery challenges and poster
                  presentations.
                </li>
                <li>Lunch and Coffee breaks.</li>
                <li>Ticket to the Welcome Reception and Conference Gala.</li>
                <li>Conference bag.</li>
              </ul>
            </div>
          </RegistrationOption>
        </div>
        <div>
          <RegistrationOption
            Logo={Logo3}
            title="One day Ticket "
            subtitle={singlePrice + "€"}
            handler={() => handleSelect("single")}
          >
            <div className="lora">
              <b>What's included:</b>
              <ul className="list-disc list-inside">
                <li>
                  All sessions of all tracks (research and ADS, journal, demo),
                  tutorials, workshops, discovery challenges and poster
                  presentations of the selected day.
                </li>
                <li>Lunch and Coffee breaks.</li>
                <li>Conference bag.</li>
              </ul>
            </div>
          </RegistrationOption>
        </div>
      </div>
    </WhiteBoxLayout>
  );
}

export default RegistrationType;
