

function Card( {Logo, title, subtitle}) {
    

    return (
        <div style={{width:"100%"}} className='overflow-x-hidden py-4 flex items-center space-y-0 space-x-6 py-8 px-8 mb-8 bg-white rounded-xl shadow-lg sm:py-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-6' >
            {Logo && <Logo/>}
            <div className="text-left space-y-2 sm:text-left">
                <div>
                    <div className="space-y-0.5">
                        <p className="text-lg text-black font-bold">
                            {title}
                        </p>
                        <p className="text-slate-500 font-medium font-lora">
                            {subtitle}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Card;


