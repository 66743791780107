import React, { useContext, useEffect, useRef, useState } from "react";
import RegistrationContext from "../../contexts/RegistrationContext";
import "./style.css";
import { ShoppingCart, Trash2 } from "lucide-react";
import axios from "axios";
import { base_url } from "../../config";

function Cart() {
  const { data, reload, setCartOpen, cart, setReload, setError } =
    useContext(RegistrationContext);

  const [inCommunication, setInCommunication] = useState(false);
  const removeItem = async (item_id) => {
    if (inCommunication) return;
    setInCommunication(true);
    setError("");
    try {
      // Make a POST request to your authentication endpoint
      const response = await axios.post(`${base_url}/api/remove_item`, {
        item_id: item_id,
      });
      setReload(reload + 1);
    } catch (error) {
      //setError("Unexpected error. " + error.response.data.message);
    }
    setInCommunication(false);
  };

  if (!cart || !cart.items) {
    return (
      <div
        className="button w-24 ml-auto mb-8"
        onClick={() => setCartOpen(false)}
      >
        {" "}
        Close{" "}
      </div>
    );
  }
  const insert_emtpy_rows =
    10 - cart.items.length > 0 ? 10 - cart.items.length : 0;
  return (

    <>

      <div className="flex text-white gap-4">
        <div>
          <ShoppingCart size={30} />
        </div>
        <div
          className="sectionTitle"
          style={{ color: "white", marginBottom: "50px" }}
        >
          Shopping Cart
        </div>
      </div>
      <div
        style={{ maxWidth: "1152px" }}
        className=" cartInfo cart-input-container flex flex-col items-center mx-auto lg:py-0 text-left"
      >
        <div
          style={{ maxWidth: "1152px", width: "100%" }}
          className="bg-white rounded-lg shadow  md:mt-0 sm:max-w-md xl:p-0  "
        >
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8 flex flex-col ">
            <table style={{ maxWidth: "500px", margin: "0 auto", width: "100%" }}>
              <thead >
                <tr>
                  <th style={{ borderBottom: "2px solid black" }}>Item</th>
                  <th
                    style={{
                      textAlign: "center",
                      borderBottom: "2px solid black",
                    }}
                  >
                    Qty
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      borderBottom: "2px solid black",
                    }}
                  >
                    Price
                  </th>
                  <th style={{ width: "0.2em" }}></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td >

                    <b>{cart.main && cart.main.type}</b>

                  </td>
                  <td style={{ textAlign: "right" }}>1</td>
                  <td
                    style={{ textAlign: "right" }}
                    className="integer-part"
                  >
                    {cart.main && Math.floor(cart.main.price)}  €
                  </td>
                  <td > {" "}</td>
                </tr>
                <tr>
                  <td colSpan={4}>&nbsp;</td>
                </tr>
                {cart.items &&
                  cart.items.map((item, index) => (
                    <tr key={index}>
                      <td>{item.description}</td>
                      <td style={{ textAlign: "right" }}>
                        {item.quantity}
                      </td>
                      <td
                        style={{ textAlign: "right" }}
                        className="integer-part"
                      >
                        {Math.floor(item.price)} €
                      </td>
                      <td style={{ textAlign: "center", height: "100%" }}>
                        <div className="pl-2 cursor-pointer text-gray-500 flex align-middle items-center hover:text-red-600">

                          <Trash2
                            onClick={(() => removeItem(item.id))}

                            size="16"
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                {insert_emtpy_rows > 0 &&
                  Array(insert_emtpy_rows)
                    .fill()
                    .map((_, index) => (
                      <tr key={index}>
                        <td colSpan={3}>&nbsp;</td>
                      </tr>
                    ))}
                <tr>
                  <td colSpan={2} style={{ textAlign: "right" }}>
                    <b>Total: </b>
                  </td>
                  <td

                    style={{ textAlign: "right" }}
                    className="integer-part text-bold"
                  >
                    <b>
                      {Math.floor(cart.total_price)} €

                    </b>
                  </td>
                  <td>
                    {" "}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

    </>
  );
}

export default Cart;
