import React, { useContext, useState } from "react";
import "./style.css";
import { useEffect } from "react";
import RegistrationContext from "../../contexts/RegistrationContext";
import { FileSearch, FileText, Trash2, Info, ShoppingCart } from "lucide-react";
import { cutFileName } from "../../util/cutFileName";
import axios from "axios";
import { base_url, stripe_key } from "../../config";
import AddToCart from "../../components/AddToCart/AddToCart";
import { loadStripe } from "@stripe/stripe-js";
import { CardElement, Elements, PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";

// Extras Cart internal component
function ExtrasCart({ cart, onRemove, onCheckout }) {
  return (
    <div className="flex flex-col w-full items-end">
      <div className="w-full p-3 bg-gray-200 text-sm">
        <div className="flex flex-col">
          <div className="flex justify-between font-bold mb-2">
            <div className="w-72">Item</div>
            <div>Qty</div>
            <div className="flex gap-2 items-center justify-start">
              Price
              <div className="w-4"></div>
            </div>
          </div>

          {cart.length > 0 &&
            cart.map((item, index) => (
              <div className="flex justify-between mb-2">
                <div className="w-72">
                  {item.id ? item.id + "-" : ""}
                  {item.description && cutFileName(item.description)}
                </div>
                <div>{item.qty}</div>
                <div className="flex gap-2 items-center justify-start">
                  €{item.price}
                  <div>
                    <Trash2
                      onClick={() => onRemove(index)}
                      size="16"
                      className="cursor-pointer"
                    />
                  </div>
                </div>
              </div>
            ))}

          <div className="flex justify-between font-bold mt-4">
            <div className="w-72"></div>
            <div></div>
            <div className="flex gap-2 items-center justify-start">
              Total: €
              {cart.reduce((sum, currentItem) => {
                return sum + currentItem.qty * currentItem.price;
              }, 0)}
              <div className="w-4"></div>
            </div>
          </div>
        </div>
      </div>

      <button
        className="button mt-2"
        style={{ backgroundColor: (cart.length == 0) ? "#ccc" : "#000" }}
        onClick={(cart.length == 0) ? () => { } : () => onCheckout()}

      >
        Checkout
      </button>
    </div>
  );
}

const stripePromise = loadStripe(stripe_key);

// Add extra paper internal component
function AddExtraPaper({ onClick }) {
  return (
    <div className="w-full flex items-center mb-4">
      <div className="w-1/3">
        <label
          htmlFor="paper-registered"
          className="block mb-2 text-sm font-medium text-gray-900"
        >
          Add extra paper:
        </label>
      </div>
      <div></div>
      <div className="w-1/3 flex justify-start pl-2">
        <div style={{ width: "60px", cursor: "pointer" }}>
          <div onClick={onClick} className={`addcart flex gap-2 `}>
            <FileSearch size={18} />
          </div>
        </div>
      </div>
    </div>
  );
}


function AddExtraItem({ item, onClick }) {
  return (
    <div className="w-full flex items-center mb-4">
      <div className="w-1/3">
        <label
          htmlFor="paper-registered"
          className="block mb-2 text-sm font-medium text-gray-900"
        >
          {item.description}:
        </label>
      </div>
      <div></div>
      <div className="w-1/3 flex justify-start pl-2 ">
        <div style={{ width: "60px", cursor: "pointer" }} >
          <div onClick={item.in_cart ? () => { } : onClick} className={`addcart flex gap-2 ${item.in_cart && "disabled"} `}>
            <ShoppingCart size={18} />
          </div>
        </div>
      </div>
    </div>
  );
}



// Main component
function Summary() {
  const {
    type,
    setPaperOpen,
    paperSelected,
    setPaperSelected,
    setStatus,
    setError,
    prices,
  } = useContext(RegistrationContext);
  const [showPayment, setShowPayment] = useState(false);
  const [extrasCart, setExtrasCart] = useState([]);
  const [papers, setPapers] = useState([]);
  const [additionalItems, setAdditionalItems] = useState([]);
  const [clientSecret, setClientSecret] = useState("");
  const [amount, setAmount] = useState(0);
  const [message, setMessage] = useState("");

  useEffect(() => {
    window.history.replaceState({}, "", "/");

    const fetchPapers = async () => {
      try {
        await axios.get(`${base_url}/api/papers`).then((response) => {
          setPapers(response.data);
        });
      } catch (error) {
        console.error("Error fetching papers:", error);
      }
    };

    const fetchAvailableItems = async () => {
      try {
        await axios.get(`${base_url}/api/get_additional_items`).then((response) => {
          setAdditionalItems({ items: response.data.items.map((item) => ({ ...item, in_cart: false })).sort((a, b) => a.type.localeCompare(b.type)) });
        });
      } catch (error) {
        console.error("Error fetching papers:", error);
      }

    };

    fetchPapers();
    fetchAvailableItems();
    setPaperSelected("");
  }, []);

  useEffect(() => {
    handleAddItem();
  }, [paperSelected]);

  function CheckoutForm() {
    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async (event) => {
      event.preventDefault();
      if (!stripe || !elements) {
        return;
      }

      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          // Make sure to change this to your payment completion page
          return_url: base_url + "/api/extra_payment/",
        },
      });

      // This point will only be reached if there is an immediate error when
      // confirming the payment. Otherwise, your customer will be redirected to
      // your `return_url`. For some payment methods like iDEAL, your customer will
      // be redirected to an intermediate site first to authorize the payment, then
      // redirected to the `return_url`.
      if (error.type === "card_error" || error.type === "validation_error") {
        setMessage(error.message);
      } else {
        setMessage("An unexpected error occurred.");
      }


    };

    return (
      <form onSubmit={(e) => handleSubmit(e)} className="payment">
        <PaymentElement
          id="payment-element"
          options={{ layout: "tabs" }}
        />

        <button type="submit" disabled={!stripe}>
          {"Pay now €" + amount}
        </button>

        {message && <div id="payment-message">{message}</div>}

      </form>
    );
  }

  // Stripe Extras page internal component
  function StripeExtras({ cart, onBackButton }) {
    // TO DO: STRIPE PAGE
    const isLoading = false;
    return (
      <div className="flex flex-col items-start">
        <button onClick={onBackButton}>{"<< Back"}</button>

        <div className="mt-4 w-full">
          <div className="w-full bg-gray-200 p-4 rounded-lg mx-auto text-center" style={{ maxWidth: "500px" }}>
            <Elements options={{ clientSecret, appearance: { theme: "stripe" } }} stripe={stripePromise}>
              <CheckoutForm />
            </Elements>
          </div>
        </div>
      </div>
    );
  }

  const handleCheckout = async () => {


    setError("");
    let clientSecret = "";
    try {
      const response = await axios.post(`${base_url}/api/extra`, {
        cart: extrasCart
      });
      setClientSecret(response.data.clientSecret);
      clientSecret = response.data.clientSecret;
    } catch (error) {
      setError("Unexpected error. " + error.response.data.message);
      return
    }

    const stripe = await stripePromise;
    const { paymentIntent, error } = await stripe.retrievePaymentIntent(clientSecret);

    if (error) {
      // Handle error
      setError("Error retrieving payment details. Please try again.");
      return;
    } else {
      // Use the PaymentIntent information

      // You can update the state or UI based on the PaymentIntent status or other information
    }



    setAmount(paymentIntent.amount / 100);
    setShowPayment(true);


  }
  // Function to add item to the extras cart
  const handleAddItem = () => {

    // When item is a paper
    if (papers.length > 0 && paperSelected && paperSelected !== "") {
      const papersInCart = extrasCart.filter((paper) => paper.type === "paper");

      if (!extrasCart.some((item) => item.id === paperSelected)) {
        if (papersInCart.length < 3) {
          const paperTitle = papers.find((paper) =>
            paperSelected.includes(paper.id)
          ).title;

          setExtrasCart((prevExtrasCart) => [
            ...prevExtrasCart,
            {
              id: paperSelected,
              type: "paper",
              qty: 1,
              description: paperTitle,
              price: prices[type],
            },
          ]);
          setStatus("You have add extra item to the cart succesfully.");
        } else {
          setError("You cannot add more extra papers.");
        }
      }
    }
    /*
      If item is no a paper, then we put here the code to add other items
      We have items with following structure:
      {
        id: "farewell-event",
        type: "attendance",
        qty: 1,
        description: "Access to farewell event",
        price: 20,
      }

      {
        id: "poster",
        type: "attendance",
        qty: 1,
        description: "Poster printing services",
        price: 20,
      }

      {
        id: "person-dinner",
        type: "attendance",
        qty: 3,
        description: "Accompanying person dinner",
        price: 80,
      },
    */
  };

  const handleAddItem2 = (item) => {

    setExtrasCart([
      ...extrasCart,
      item,
    ]);
    const updatedAddionalItems = additionalItems.items.filter((i) => i.type !== item.type);
    setAdditionalItems({ items: [...updatedAddionalItems, { ...item, in_cart: true }].sort((a, b) => a.type.localeCompare(b.type)) });

  }

  // Function to remove item to the extras cart by its array position
  const handleCartItemRemove = (idx) => {
    
    const rmv = extrasCart[idx];

    setExtrasCart((prevExtrasCart) =>
      prevExtrasCart.filter((item, index) => index !== idx)
    );
    if (rmv.type != "paper") {
      const updatedAddionalItems = additionalItems.items.filter((i) => i.type !== rmv.type);
      setAdditionalItems({ items: [...updatedAddionalItems, { ...rmv, in_cart: false }].sort((a, b) => a.type.localeCompare(b.type)) });
    }
    setStatus("You have remove extra item from the cart succesfully.");
  };

  return (
    <div className="flex flex-col h-full grow">
      <div
        style={{ maxWidth: "1152px", width: "100%" }}
        className="flex justify-start flex-col h-full items-center mx-auto lg:py-0 text-left"
      >
        <div
          style={{ maxWidth: "1152px", width: "100%", maxHeight: "100%" }}
          className="bg-white md:mt-0 sm:max-w-md xl:p-0  "
        >
          <div>
            <div className="flex flex-col h-full bg-white rounded-md">
              <div
                style={{ maxWidth: "1152px", width: "100%" }}
                className="flex justify-center flex-col h-full items-center mx-auto lg:py-0 text-left"
              >
                <div className="w-full p-6 space-y-4 md:space-y-6 sm:p-8 summary-page-content">
                  <h1 className="text-ecmlBrown  text-center px-10 py-4 rounded-md text-2xl underline">
                    Extras
                  </h1>

                  <div>
                    {!showPayment ? (
                      <>
                        <div className="w-full mt-10 flex flex-col">
                          <div className="py-2 font-bold leading-tight tracking-tight text-gray-900 text-xl flex gap-2 border-b-slate-200 border-b mb-4">
                            <FileText /> Add more items
                          </div>

                          <div className="flex flex-col sm:flex-row sm:gap-10 md:gap-32 items-start">
                            {/* ADD MORE ITEMS */}
                            <div className="w-full flex items-center flex-col">
                              <AddExtraPaper
                                onClick={() => setPaperOpen(true)}
                              />

                              {/*
															<AddExtraFarewellEvent onClick={() => {}} />

                              <AddExtraPoster onClick={() => {}} />

                              <AddExtraAcompanying onClick={() => {}} />
															*/}
                              {additionalItems.items && additionalItems.items.map((item, index) => (

                                <AddExtraItem item={item} onClick={() => handleAddItem2(item)} />
                              )
                              )}
                            </div>


                            {/* CART */}
                            <ExtrasCart
                              cart={extrasCart}
                              onRemove={(idx) => handleCartItemRemove(idx)}
                              onCheckout={() => handleCheckout()}
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <StripeExtras
                          cart={extrasCart}
                          onBackButton={() => setShowPayment(false)}
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col h-full  mt-8">
              <div
                style={{
                  maxWidth: "1152px",
                  width: "100%",
                  minHeight: "150px",
                  backgroundImage: `url('https://d1u5q3vcv714vh.cloudfront.net/eyJidWNrZXQiOiJlY21scGtkZC1zdG9yYWdlIiwia2V5IjoiYmFja2dyb3VuZF83ZDc0ZGUwYTZjLndlYnAifQ==')`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  borderRadius: "10px",
                }}
                className=" h-full  mx-auto lg:py-0 text-left"
              >
                <div
                  style={{
                    color: "white",
                    fontSize: "50px",
                    fontWeight: "900",
                    paddingLeft: "40px",
                  }}
                >
                  See you soon in Vilnius :)
                </div>
                <div
                  style={{
                    color: "white",
                    fontSize: "20px",
                    fontWeight: "90",
                    paddingLeft: "40px",
                  }}
                >
                  For travel information and exclusive accommodation deals, be
                  sure to visit our{" "}
                  <a
                    style={{ textDecoration: "underline" }}
                    target="_blank"
                    href="https://ecmlpkdd.org/2024/"
                    rel="noreferrer"
                  >
                    website
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Summary;
