import { useContext } from "react";
import RegistrationContext from "../../contexts/RegistrationContext";
import Logout from "../../pages/Logout";
import LeaveImpersonation from "../../components/LeaveImpersonation";
import "./style.css";
import CartIcon from "../CartIcon/CartIcon";
import { User, UserCog } from "lucide-react";
function AuthStatus() {
  const {
    authenticated,
    screen,
    setScreen,
    maxStep,
    setUserInfoOpen,
    isAdmin,
    isViewer,
    isImpersonating,
    reload,
    setReload,
  } = useContext(RegistrationContext);

  const showCart =
    maxStep !== "Registration Type" && maxStep !== "Confirmation";
  const isAdminScreen = screen.startsWith("admin");
  const isViewerScreen = screen.startsWith("viewer");

  const adminIconClick = () => {
    if (isAdminScreen) {
      setScreen(maxStep);
      setReload(reload + 1);
    } else {
      setScreen("admin-dashboard");
    }
  };

  const viewerIconClick = () => {
    if (isViewerScreen) {
      setScreen(maxStep);
      setReload(reload + 1);
    } else {
      setScreen("viewer-registrations");
    }
  };
  if (authenticated) {
    return (
      <div className="flex">
        {!isAdminScreen && (
          <>
            <div className="ml-6">
              <User
                onClick={() => {
                  setUserInfoOpen(true);
                }}
                style={{ cursor: "pointer" }}
              />
            </div>
            {showCart && (
              <div className="ml-6">
                <CartIcon />
              </div>
            )}
          </>
        )}
        {(isAdmin ) && (
          <div className="ml-6 text-pink-500 cursor-pointer">
            <UserCog onClick={() => adminIconClick()} />
          </div>
        )}
        {(isViewer ) && (
          <div className="ml-6 text-yellow-500 cursor-pointer">
            <UserCog onClick={() => viewerIconClick()} />
          </div>
        )}
        {isImpersonating ? (
          <div className="ml-6">
            <LeaveImpersonation />
          </div>
        ) : (
          <div className="ml-6">
            <Logout />
          </div>
        )}
      </div>
    );
  } else {
    return (
      <div>
        <button className="button" onClick={() => setScreen("signin")}>
          Sign up
        </button>
      </div>
    );
  }
}

export default AuthStatus;
