import React, { useContext } from 'react';
import axios from 'axios';
import RegistrationContext from '../contexts/RegistrationContext';
import setAuthToken from '../authtoken';
import { base_url } from '../config';
import { LogOut } from 'lucide-react';

function Logout() {
  const { setScreen, setAuthenticated, setError, reload, setReload } = useContext(RegistrationContext);
  const handleLogout = async () => {
    setError('');
    try {
      await axios.post(`${base_url}/api/logout`, {});
      setAuthenticated(false);
      localStorage.removeItem('token');
      setAuthToken(false);
      setScreen('login');
      setReload(reload + 1);

    } catch (error) {
      console.error(error);
      setAuthenticated(false);
      localStorage.removeItem('token');
      setAuthToken(false);
      setScreen('login');
      setReload(reload + 1);
    }
  };
  return (
    <>
      <LogOut className="cursor-pointer" alt="Logout" color="black" size={24} onClick={() => handleLogout()} />

    </>
  );
}

export default Logout;
