import React, { useEffect, useRef, useState } from "react";

function WhiteBoxLayout(props) {
    const [hideMargin, setHideMargin] = useState(false);
    const divBox = useRef(null);
    const divContainer = useRef(null);

    useEffect(() => {
        const handleResize = () => {
            const height_box = divBox.current.offsetHeight;
            const height_container = divContainer.current.offsetHeight;
            setHideMargin(height_box + 40 > height_container)
        };

        window.addEventListener('resize', handleResize);
        handleResize();
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    let classNameBox = "bg-white rounded-lg shadow  md:mt-0 xl:p-0 ";
    if (props.gray) {
        classNameBox = " md:mt-0 xl:p-0 ";
    }

    return (
        <div ref={divContainer} className="flex flex-col h-full">
            <div className="sectionTitle mt-2"> { " " }</div>
            <div style={{ maxWidth: "1152px", width: "100%" }}
                className="flex  flex-col  items-center mx-auto lg:py-0 text-left">
                {!hideMargin && (
                    <div className="flex-grow-0 flex-shrink h-12">{" "}</div>
                )}
                <div ref={divBox}
                    style={{ maxWidth: "1152px", width: "100%", minHeight: "200px" }}
                    className={classNameBox}>
                    {props.children}
                </div>
            </div>
        </div >

    );
}

export default WhiteBoxLayout;
