import { useContext } from "react";
import RegistrationContext from "../contexts/RegistrationContext";
import Spinner from "../components/Spinner/Spinner";
import Registrations from "../admin-pages/Registrations/Registrations";
import Papers from "../admin-pages/Papers/Papers";
import Dashboard from "../admin-pages/Dashboard/Dashboard";
import Codes from "../admin-pages/Codes/Codes";
import AdminLayout from "../components/AdminLayout/AdminLayout";
import { Barcode, LayoutDashboard, User2, Layers } from "lucide-react";

const MenuAdmin = ({ handler }) => {
  return (
    <div className="flex flex-col gap-4 mt-2">
      <div
        className="flex gap-4 cursor-pointer items-center"
        onClick={() => handler("admin-dashboard")}
      >
        <LayoutDashboard size={18} />
        Dashboard
      </div>
      <div
        className="flex gap-4 cursor-pointer items-center"
        onClick={() => handler("admin-registrations")}
      >
        <User2 size={18} />
        Registrations
      </div>
      <div
        className="flex gap-4 cursor-pointer items-center"
        onClick={() => handler("admin-codes")}
      >
        <Barcode size={18} /> Codes
      </div>
      <div
        className="flex gap-4 cursor-pointer items-center"
        onClick={() => handler("admin-papers")}
      >
        <Layers size={18} /> Papers
      </div>
    </div>
  );
};

const AdminUser = () => {
  const { screen, setScreen, loading } = useContext(RegistrationContext);

  /* TODO */
  return (
    <>
      <div
        className="maincnt h-full border border-t-gray-200"
        style={{ backgroundColor: "#2e96ff" }}
      >
        {loading ? (
          <div className="flex justify-center h-full items-center">
            <Spinner />
          </div>
        ) : (
          <div className="font-bold flex h-full">
            <div
              className="px-8 py-2 text-ecmlBrown border-0 border-r-ecmlOrange border-t-0 text-white z-30 fixed h-full"
              style={{ backgroundColor: "#2e96ff" }}
            >
              <MenuAdmin handler={setScreen} />
            </div>
            <div
              className="bg-white w-full h-full grow"
              style={{ paddingLeft: "12.7rem" }}
            >
              {screen === "admin-dashboard" && (
                <AdminLayout title="Dashboard" icon={<LayoutDashboard />}>
                  <Dashboard />
                </AdminLayout>
              )}
              {screen === "admin-registrations" && (
                <AdminLayout title="Registrations" icon={<User2 />}>
                  <Registrations />
                </AdminLayout>
              )}
              {screen === "admin-codes" && (
                <AdminLayout title="Codes" icon={<Barcode />}>
                  <Codes />
                </AdminLayout>
              )}
              {screen === "admin-papers" && (
                <AdminLayout title="Papers" icon={<Layers />}>
                  <Papers />
                </AdminLayout>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default AdminUser;
