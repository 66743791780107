import React, { useState, useEffect, useContext } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import CheckoutForm from "./CheckoutForm";
import './style.css';
import { base_url, stripe_key } from "../../config";
import RegistrationContext from "../../contexts/RegistrationContext";
import FreePayment from "./FreePayment";
import axios from "axios";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
const stripePromise = loadStripe(stripe_key);

export default function App() {
  const [clientSecret, setClientSecret] = useState("");
  const [loading, setIsLoading] = useState(true);
  const { data,setError } = useContext(RegistrationContext);

  useEffect(() => {
    setIsLoading(true);
    // Create PaymentIntent as soon as the page loads
    axios
      .post(`${base_url}/api/checkout`, { items: [{ id: `Reg. ${data.id}` }] })
      .then((res) => {
        setIsLoading(false);
        setClientSecret(res.data.clientSecret);
      }).catch((err) => {
          setIsLoading(false);
      });
  }, []);

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };


  if (loading) return <p>Loading...</p>;

  return (
    <>
      {        
        (data.price > 0 ? (
          <div className="payment">
            <Elements options={options} stripe={stripePromise}>
              <CheckoutForm />
            </Elements>
          </div>
        ) : (
          <div className="freePayment">
            <FreePayment />
          </div>
        ))}

    </>
  );
}