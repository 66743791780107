import { useEffect, useMemo, useState } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { base_url } from "../../config";
import axios from "axios";

export default function App() {
  //column definitions - strongly typed if you are using TypeScript (optional, but recommended)
  const columns = useMemo(
    () => [
      {
        accessorKey: "code", //simple recommended way to define a column
        header: "Code",
        muiTableHeadCellProps: { style: { color: "#444040" } }, //custom props
        enableHiding: false, //disable a feature for this column
      },
      {
        accessorKey: "status", //simple recommended way to define a column
        header: "Status",
        muiTableHeadCellProps: { style: { color: "#444040" } }, //custom props
        enableHiding: false, //disable a feature for this column
      },

      {
        accessorKey: "email", //simple recommended way to define a column
        header: "Email",
        muiTableHeadCellProps: { style: { color: "#444040" } }, //custom props
        enableHiding: false, //disable a feature for this column
      },
      {
        accessorKey: "name", //simple recommended way to define a column
        header: "Name",
        muiTableHeadCellProps: { style: { color: "#444040" } }, //custom props
        enableHiding: false, //disable a feature for this column
      },

      // {
      //     accessorFn: (originalRow) => parseInt(originalRow.age), //alternate way
      //     id: 'age', //id required if you use accessorFn instead of accessorKey
      //     header: 'Age',
      //     Header: <i style={{ color: 'red' }}>Age</i>, //optional custom markup
      //     Cell: ({ cell }) => <i>{cell.getValue().toLocaleString()}</i>, //optional custom cell render
      // },
    ],
    []
  );

  const [data, setData] = useState([]);
  const [reload, setReload] = useState(0);

  const createCode = async (type) => {
    const response = await axios.post(`${base_url}/api/admin/codes`, { type });
    setReload(reload + 1);
  };

  useEffect(() => {
    const loadData = async () => {
      const comm = await axios.get(`${base_url}/api/admin/codes`);
      //const data = await response.json();
      setData(comm.data);
    };

    loadData();
  }, [reload]);

  //pass table options to useMaterialReactTable
  const table = useMaterialReactTable({
    columns,
    data, //must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    initialState: { density: "compact" },
    enableRowSelection: true, //enable some features
    enableColumnOrdering: true, //enable a feature for all columns
    enableGlobalFilter: true, //turn off a feature
    muiTableHeadCellProps: {
      // simple styling with the `sx` prop, works just like a style prop in this example
      // https://www.material-react-table.com/docs/guides/customize-components#the-sx-prop
      sx: {
        backgroundColor: "#e3f1ff",
      },
    },
  });

  return (
    <>
      <div className="flex mb-8 gap-4">
        <div className="button text-sm " onClick={() => createCode("vol")}>
          Create Volunteer Code
        </div>
        <div className="button text-sm" onClick={() => createCode("org")}>
          Create Oranizer Code
        </div>
        <div className="button text-sm" onClick={() => createCode("inv")}>
          Create Invitation Code
        </div>
      </div>
      <MaterialReactTable table={table} className="h-full" />
    </>
  );
}
