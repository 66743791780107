import { useContext } from "react";
import RegistrationContext from "../contexts/RegistrationContext";
import LoginForm from "../pages/LoginForm/LoginForm";
import Signin from "../pages/Signin";
import ForgotPass from "../pages/ForgotPass";
import Spinner from "../components/Spinner/Spinner";


const NotAuthenticated = () => {

    const {
        screen,
        loading
    } = useContext(RegistrationContext);

    return (
        <>
            <div className="maincnt bg-custombg ">
                <div className="main  ">
                    {loading ?
                        (
                            <div className="flex justify-center h-full items-center">
                                <Spinner />
                            </div>
                        )
                        :
                        (
                            <>
                                {screen === "login" && <LoginForm />}
                                {screen === "signin" && <Signin />}
                                {screen === "forgotpass" && <ForgotPass />}
                            </>
                        )
                    }
                </div>
            </div>
        </>
    );
}

export default NotAuthenticated;