import React, { useContext, useState } from "react";
import RegistrationContext from "../contexts/RegistrationContext";
import SidePanel from "../components/SidePanel/SidePanel";
import Cart from "../pages/Cart/Cart";
import UserInfo from "../pages/UserInfo/UserInfo";
import SearchPapers from "../components/SearchPapers/SearchPapers";
import Spinner from "../components/Spinner/Spinner";
import Summary from "../pages/Summary/Summary";
import Billing from "../pages/Summary/Billing";
import Logout from "../pages/Logout";
import Extras from "../pages/Summary/Extras";
import Stepper from "../components/Stepper/Stepper";

const RegistrationComplete = () => {
  const {
    loading,
    cartOpen,
    setCartOpen,
    userInfoOpen,
    setUserInfoOpen,
    paperOpen,
    setPaperOpen,
  } = useContext(RegistrationContext);

  const [summarySelectedMenu, setSummarySelectedMenu] = useState({
    menuItem: "summary",
    content: <Summary />,
  });

  return (
    <>
      <SidePanel isOpen={cartOpen} openCloseHandler={setCartOpen}>
        <Cart />
      </SidePanel>

      <SidePanel isOpen={userInfoOpen} openCloseHandler={setUserInfoOpen}>
        <UserInfo />
      </SidePanel>

      <SidePanel isOpen={paperOpen} openCloseHandler={setPaperOpen}>
        <SearchPapers />
      </SidePanel>

      <div className="bg-custombg">
        <div className="stepper">
          <Stepper />
        </div>
      </div>

      <div className="maincnt bg-custombg flex flex-row">
        <div className="main flex flex-col lg:flex-row relative">
          <div
            className="summary-menu absolute top-4 right-8 w-40 rounded-lg text-white font-bold p-4"
            style={{ backgroundColor: "#f90" }}
          >
            <ul>
              <li
                onClick={(e) => {
                  setSummarySelectedMenu({
                    menuItem: "summary",
                    content: <Summary />,
                  });
                }}
                className={`cursor-pointer ${
                  summarySelectedMenu.menuItem === "summary" ? "selected" : ""
                }`}
              >
                Summary
                <hr className="w-8 my-1" />
              </li>
              <li
                onClick={(e) => {
                  setSummarySelectedMenu({
                    menuItem: "extras",
                    content: <Extras />,
                  });
                }}
                className={`cursor-pointer ${
                  summarySelectedMenu.menuItem === "extras" ? "selected" : ""
                }`}
              >
                Extras
                <hr className="w-8 my-1" />
              </li>
              <li
                onClick={(e) => {
                  setSummarySelectedMenu({
                    menuItem: "billing",
                    content: <Billing />,
                  });
                }}
                className={`cursor-pointer ${
                  summarySelectedMenu.menuItem === "billing" ? "selected" : ""
                }`}
              >
                Billing
              </li>
            </ul>
          </div>

          {loading ? (
            <div className="flex justify-center h-full items-center">
              <Spinner />
            </div>
          ) : (
            <>{summarySelectedMenu.content}</>
          )}
        </div>
      </div>
    </>
  );
};

export default RegistrationComplete;
