import { useContext } from "react";
import RegistrationContext from "../contexts/RegistrationContext";
import SidePanel from "../components/SidePanel/SidePanel";
import Cart from "../pages/Cart/Cart";
import UserInfo from "../pages/UserInfo/UserInfo";
import SearchPapers from "../components/SearchPapers/SearchPapers";
import Stepper from "../components/Stepper/Stepper";
import Spinner from "../components/Spinner/Spinner";
import RegistrationType from "../pages/RegistrationType/RegistrationType";
import PersonalData from "../pages/PersonalData";
import Payment from "../pages/Payment/Payment";
import WorkshopSelection from "../pages/WorkshopSelection/WorkshopSelection";
import Extras from "../pages/Extras/Extras";



const RegularUser = () => {

    const {
        screen,
        loading,
        cartOpen,
        setCartOpen,
        userInfoOpen,
        setUserInfoOpen,
        paperOpen,
        setPaperOpen
    } = useContext(RegistrationContext);

    return (
        <>
            <SidePanel isOpen={cartOpen} openCloseHandler={setCartOpen}>
                <Cart />
            </SidePanel>

            <SidePanel isOpen={userInfoOpen} openCloseHandler={setUserInfoOpen}>
                <UserInfo />
            </SidePanel>

            <SidePanel isOpen={paperOpen} openCloseHandler={setPaperOpen}>
                <SearchPapers />
            </SidePanel>

            <div className="bg-custombg">
                <div className="stepper">
                    <Stepper />
                </div>
            </div>

            <div className="maincnt bg-custombg ">
                <div className="main  ">
                    {
                        loading ?
                            (
                                <div className="flex justify-center h-full items-center">
                                    <Spinner />
                                </div>
                            ) : (
                                <>
                                    {screen === "Registration Type" && <RegistrationType />}
                                    {screen === "Personal Data" && <PersonalData />}
                                    {screen === "Workshop Selection" && <WorkshopSelection />}
                                    {screen === "Extras" && <Extras />}
                                    {screen === "Payment" && <Payment />}
                                   
                                </>
                            )
                    }

                </div>
            </div>
        </>
    );
}

export default RegularUser;