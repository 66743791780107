import { useContext } from "react";
import { deadlines } from "../../config";
import "./style.css";
import RegistrationContext from "../../contexts/RegistrationContext";

function Footer() {
  const { phase, deadline } = useContext(RegistrationContext);

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  }

  return (
    <div className="flex justify-center bg-ecmlBrown py-2 relative z-50">
      <div className="footer"></div>
      <div></div>
      <div className="font-bold text-white">
        {phase !== "" && (
          <>
            In {phase} registration until {formatDate(deadline)}
          </>
        )}
       
      </div>
    </div>
  );
}

export default Footer;
